import { deserializeJsonObj, deserializeJsonObjCollection } from '@utils/ObjectMapperUtil';

import { APIBaseServices, clearCacheForService } from '@common/network/clientCacheHandler';
import { APIv1 } from "@common/network/constants";
import { ICreateProfilePayload } from "@common/redux/auth/interface";
import { CLIENT_NAME } from "constants/config"
import { IDepartment, IMapUserInvitationPayload, IProfile, IUserEducationQualification, ProfileModel } from '@models/Profile';

import { ApiClient, wfcommonApiClient } from "@network/client";

const ENDPOINTS = {
  fetchProfilesByUsersIds: () => `${APIv1}/profileservice/users`,
  updateProfile: (userId: string) => `${APIv1}/profileservice/users/${userId}`,
  fetchEducationQualifications: () => `${APIv1}/organizationservice/educationQualifications`,
  fetchDepartments: () => `${APIv1}/organizationservice/ministryGroups?include=ministries`,
  fetchProfileFromEmailId: () => `${APIv1}/profileservice/userprofile`,
  mapUserInvitations: () => `${APIv1}/organizationservice/mapUserInvitations`,
  profileService: () => `${APIv1}/tagsservice/tags`,
  deleteUser: (userId: string) => `${APIv1}/profileservice/profile/delete?userId=${userId}`,
};

class ProfileRepository {
  private apiClient: any;
  private commonApiClient: any;

  constructor(apiClient: any, commonApiClient: any) {
    this.apiClient = apiClient;
    this.commonApiClient = commonApiClient;
  }

  public fetchProfilesByUserIds = async (userIds: string[]): Promise<ProfileModel[]> => {
    // Helper function to split array into chunks of 30
    const chunkArray = (array: string[], size: number): string[][] => {
      return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
        array.slice(index * size, index * size + size)
      );
    };
  
    const userGroups = chunkArray(userIds, 25); // Split userIds into groups of 25
  
    const fetchBatch = async (batch: string[]): Promise<ProfileModel[]> => {
      const params = { userId: batch.toString() };
      const response = await this.apiClient.get(ENDPOINTS.fetchProfilesByUsersIds(), params, true);
      return deserializeJsonObjCollection(response.profile, ProfileModel, 'fetchProfilesByUserIds') as ProfileModel[];
    };
  
    // Execute all requests in parallel using Promise.all
    const results = await Promise.all(userGroups.map(group => fetchBatch(group)));
  
    // Flatten results to return a single array of profiles
    return results.flat();
  };

  public createProfile = async (payload: ICreateProfilePayload) => {
    const { email, firstName, lastName, location, preferredLanguage, candidateId, department, government ,countryCode,phoneNumber} = payload;
    const apiPayload = {
      email, firstName, lastName, location, preferredLanguage, candidateId, department, government,countryCode,phoneNumber
    }
    const response = await this.apiClient.post(ENDPOINTS.fetchProfilesByUsersIds(), apiPayload);
    return response;
  };

  public putProfile = async (payload: { profile: IProfile, userId: string }) => {
    clearCacheForService(APIBaseServices.PROFILE_SERVICE);
    clearCacheForService(APIBaseServices.ORGANISATION_SERVICE);
    clearCacheForService(APIBaseServices.COURSE_SERVICE);
    clearCacheForService(APIBaseServices.TAGS_SERVICE);
    clearCacheForService(APIBaseServices.IAM_SERVICE);
    clearCacheForService(APIBaseServices.SCHEDULING_SERVICE);
    const response = await this.apiClient.put(
      ENDPOINTS.updateProfile(payload.userId),
      payload.profile
    );
    return deserializeJsonObj(response.profile, ProfileModel, 'putProfile');
  };

  public fetchEducationQualifications = async (): Promise<IUserEducationQualification[]> => {
    const response = await this.apiClient.get(ENDPOINTS.fetchEducationQualifications())
    return response;
  }

  public fetchDepartments = async (): Promise<IDepartment[]> => {
    const response = await this.apiClient.get(ENDPOINTS.fetchDepartments())
    return response;
  }

  public fetchProfileFromEmailId = async (email: string) => {
    const params = {
      email
    };
    const response = await this.apiClient.get(ENDPOINTS.fetchProfileFromEmailId(), params);
    const profile = deserializeJsonObj(response[0], ProfileModel, 'fetchProfileFromEmailId');
    return profile;
  }

  public mapUserInvitations = async (payload: IMapUserInvitationPayload) => {
    const response = await this.apiClient.post(ENDPOINTS.mapUserInvitations(), payload);
    return response;
  }

  public profileService = async (payload) => {
    const response = await this.commonApiClient.get(ENDPOINTS.profileService(), { products: CLIENT_NAME, ...payload });
    return response?.tags[0]?.tags[0];
  };

  public deleteUser = async (userId: string): Promise<{}> => {
    const response = await this.apiClient.delete(ENDPOINTS.deleteUser(userId));
    return response;
  };
}


const profileRepository = new ProfileRepository(ApiClient, wfcommonApiClient);

export { profileRepository as ProfileRepository };

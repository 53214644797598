import { CourseCompetencyModel } from "@common/domain/models/CourseCompetency";
import { CourseToc, IMods } from "@models/CourseToc";
import { IFluxStandardAction } from '@store/webInterfaces';
import { CourseModel, IEnrolledCourse, IFetchCourseActivity, IFetchEnrolledCourses, ILMSSession } from "@models/Course";

const actionTypePrefix = "COURSE";

export const courseActions = {
  FETCH_MULTIPLE_COURSES_DETAILS: `${actionTypePrefix}/FETCH_MULTIPLE_COURSES_DETAILS`,
  FETCH_MULTIPLE_COURSES_DETAILS_SUCCESS: `${actionTypePrefix}/FETCH_MULTIPLE_COURSES_DETAILS_SUCCESS`,
  FETCH_MULTIPLE_COURSES_DETAILS_FAIL: `${actionTypePrefix}/FETCH_MULTIPLE_COURSES_DETAILS_FAIL`,

  FETCH_COURSE_COMPETENCIES: `${actionTypePrefix}/FETCH_COURSE_COMPETENCIES`,
  FETCH_COURSE_COMPETENCIES_SUCCESS: `${actionTypePrefix}/FETCH_COURSE_COMPETENCIES_SUCCESS`,
  FETCH_COURSE_COMPETENCIES_FAIL: `${actionTypePrefix}/FETCH_COURSE_COMPETENCIES_FAIL`,

  FETCH_COURSE_TAGS: `${actionTypePrefix}/FETCH_COURSE_TAGS`,
  FETCH_COURSE_TAGS_SUCCESS: `${actionTypePrefix}/FETCH_COURSE_TAGS_SUCCESS`,
  FETCH_COURSE_TAGS_FAIL: `${actionTypePrefix}/FETCH_COURSE_TAGS_FAIL`,

  FETCH_FACULTY_COURSES: `${actionTypePrefix}/FETCH_FACULTY_COURSES`,
  FETCH_FACULTY_COURSES_SUCCESS: `${actionTypePrefix}/FETCH_FACULTY_COURSES_SUCCESS`,
  FETCH_FACULTY_COURSES_FAIL: `${actionTypePrefix}/FETCH_FACULTY_COURSES_FAIL`,

  FETCH_ENROLLED_COURSES: `${actionTypePrefix}/FETCH_ENROLLED_COURSES`,
  FETCH_ENROLLED_COURSES_SUCCESS: `${actionTypePrefix}/FETCH_ENROLLED_COURSES_SUCCESS`,
  FETCH_ENROLLED_COURSES_FAIL: `${actionTypePrefix}/FETCH_ENROLLED_COURSES_FAIL`,

  FETCH_COURSE_ACTIVITY_PERCENTAGE: `${actionTypePrefix}/FETCH_COURSE_ACTIVITY_PERCENTAGE`,
  FETCH_COURSE_ACTIVITY_PERCENTAGE_SUCCESS: `${actionTypePrefix}/FETCH_COURSE_ACTIVITY_PERCENTAGE_SUCCESS`,
  FETCH_COURSE_ACTIVITY_PERCENTAGE_FAIL: `${actionTypePrefix}/FETCH_COURSE_ACTIVITY_PERCENTAGE_FAIL`,

  FETCH_COURSE_CONTENT: `${actionTypePrefix}/FETCH_COURSE_CONTENT`,
  FETCH_COURSE_CONTENT_SUCCESS: `${actionTypePrefix}/FETCH_COURSE_CONTENT_SUCCESS`,
  FETCH_COURSE_CONTENT_FAIL: `${actionTypePrefix}/FETCH_COURSE_CONTENT_FAIL`,

  FETCH_LMS_SESSION: `${actionTypePrefix}/FETCH_LMS_SESSION`,
  FETCH_LMS_SESSION_SUCCESS: `${actionTypePrefix}/FETCH_LMS_SESSION_SUCCESS`,
  FETCH_LMS_SESSION_FAIL: `${actionTypePrefix}/FETCH_LMS_SESSION_FAIL`,

  FETCH_ACTIVITY_STATUS: `${actionTypePrefix}/FETCH_ACTIVITY_STATUS`,
  FETCH_ACTIVITY_STATUS_SUCCESS: `${actionTypePrefix}/FETCH_ACTIVITY_STATUS_SUCCESS`,
  FETCH_ACTIVITY_STATUS_FAIL: `${actionTypePrefix}/FETCH_ACTIVITY_STATUS_FAIL`,

  GET_CERTIFICATE_ID: `${actionTypePrefix}/GET_CERTIFICATE_ID`,
  GET_CERTIFICATE_ID_SUCCESS: `${actionTypePrefix}/GET_CERTIFICATE_ID_SUCCESS`,
  GET_CERTIFICATE_ID_FAIL: `${actionTypePrefix}/GET_CERTIFICATE_ID_FAIL`,

  CONNECT_DISCOURSE: `${actionTypePrefix}/CONNECT_DISCOURSE`,
  CONNECT_DISCOURSE_SUCCESS: `${actionTypePrefix}/CONNECT_DISCOURSE_SUCCESS`,
  CONNECT_DISCOURSE_FAIL: `${actionTypePrefix}/CONNECT_DISCOURSE_FAIL`,

  UPDATE_LAST_VISITED_TIME: `${actionTypePrefix}/UPDATE_LAST_VISITED_TIME`,
  UPDATE_LAST_VISITED_TIME_SUCCESS: `${actionTypePrefix}/UPDATE_LAST_VISITED_TIME_SUCCESS`,
  UPDATE_LAST_VISITED_TIME_FAIL: `${actionTypePrefix}/UPDATE_LAST_VISITED_TIME_FAIL`,

  FETCH_UPDATED_COURSE_TOC: `${actionTypePrefix}/FETCH_UPDATED_COURSE_TOC`,
  
  FETCH_CERTIFIED_FACULTY_COURSES: `${actionTypePrefix}/FETCH_CERTIFIED_FACULTY_COURSES`,
  FETCH_CERTIFIED_FACULTY_COURSES_SUCCESS: `${actionTypePrefix}/FETCH_CERTIFIED_FACULTY_COURSES_SUCCESS`,
  FETCH_CERTIFIED_FACULTY_COURSES_FAIL: `${actionTypePrefix}/FETCH_CERTIFIED_FACULTY_COURSES_FAIL`,

  FETCH_LATEST_COURSES_BY_TAGS: `${actionTypePrefix}/FETCH_LATEST_COURSES_BY_TAGS`,
  FETCH_LATEST_COURSES_BY_TAGS_SUCCESS: `${actionTypePrefix}/FETCH_LATEST_COURSES_BY_TAGS_SUCCESS`,
  FETCH_LATEST_COURSES_BY_TAGS_FAIL: `${actionTypePrefix}/FETCH_LATEST_COURSES_BY_TAGS_FAIL`,
  
  FETCH_OPEN_COURSES_LIST: `${actionTypePrefix}/FETCH_OPEN_COURSES_LIST`,
  FETCH_OPEN_COURSES_LIST_SUCCESS: `${actionTypePrefix}/FETCH_OPEN_COURSES_LIST_SUCCESS`,
  FETCH_OPEN_COURSES_LIST_FAIL: `${actionTypePrefix}/FETCH_OPEN_COURSES_LIST_FAIL`,

  ENROLL_TO_COURSE: `${actionTypePrefix}/ENROLL_TO_COURSE`,
  ENROLL_TO_COURSE_SUCCESS: `${actionTypePrefix}/ENROLL_TO_COURSE_SUCCESS`,
  ENROLL_TO_COURSE_FAIL: `${actionTypePrefix}/ENROLL_TO_COURSE_FAIL`,
  
  FETCH_AI_LESSON_MAPPING: `${actionTypePrefix}/FETCH_AI_LESSON_MAPPING`,
  FETCH_AI_LESSON_MAPPING_SUCCESS: `${actionTypePrefix}/FETCH_AI_LESSON_MAPPING_SUCCESS`,
  FETCH_AI_LESSON_MAPPING_FAIL: `${actionTypePrefix}/FETCH_AI_LESSON_MAPPING_FAIL`,

  FETCH_ENROLLED_COURSE_KEYS: `${actionTypePrefix}/FETCH_ENROLLED_COURSE_KEYS`,
  FETCH_ENROLLED_COURSE_KEYS_SUCCESS: `${actionTypePrefix}/FETCH_ENROLLED_COURSE_KEYS_SUCCESS`,
  FETCH_ENROLLED_COURSE_KEYS_FAIL: `${actionTypePrefix}/FETCH_ENROLLED_COURSE_KEYS_FAIL`,

  GET_MICRO_CERTIFICATE_ID: `${actionTypePrefix}/GET_MICRO_CERTIFICATE_ID`,
  GET_MICRO_CERTIFICATE_ID_SUCCESS: `${actionTypePrefix}/GET_MICRO_CERTIFICATE_ID_SUCCESS`,
  GET_MICRO_CERTIFICATE_ID_FAIL: `${actionTypePrefix}/GET_MICRO_CERTIFICATE_ID_FAIL`,

  GET_FORUM_DETAILS_BYCOURSEID: `${actionTypePrefix}/GET_FORUM_DETAILS_BYCOURSEID`,
  GET_FORUM_DETAILS_BYCOURSEID_SUCCESS: `${actionTypePrefix}/GET_FORUM_DETAILS_BYCOURSEID_SUCCESS`,
  GET_FORUM_DETAILS_BYCOURSEID_FAIL: `${actionTypePrefix}/GET_FORUM_DETAILS_BYCOURSEID_FAIL`,

  DISCOURSE_SYNC_TO_GROUP: `${actionTypePrefix}/DISCOURSE_SYNC_TO_GROUP`,
  DISCOURSE_SYNC_TO_GROUP_SUCCESS: `${actionTypePrefix}/DISCOURSE_SYNC_TO_GROUP_SUCCESS`,
  DISCOURSE_SYNC_TO_GROUP_FAIL: `${actionTypePrefix}/DISCOURSE_SYNC_TO_GROUP_FAIL`,

  FETCH_DEFAULT_OPEN_COURSES_LIST: `${actionTypePrefix}/FETCH_DEFAULT_OPEN_COURSES_LIST`,
  FETCH_DEFAULT_OPEN_COURSES_LIST_SUCCESS: `${actionTypePrefix}/FETCH_DEFAULT_OPEN_COURSES_LIST_SUCCESS`,
  FETCH_DEFAULT_OPEN_COURSES_LIST_FAIL: `${actionTypePrefix}/FETCH_DEFAULT_OPEN_COURSES_LIST_FAIL`,

  FETCH_INSTITUTE_COURSES: `${actionTypePrefix}/FETCH_INSTITUTE_COURSES`,
  FETCH_INSTITUTE_COURSES_SUCCESS: `${actionTypePrefix}/FETCH_INSTITUTE_COURSES_SUCCESS`,
  FETCH_INSTITUTE_COURSES_FAIL: `${actionTypePrefix}/FETCH_INSTITUTE_COURSES_FAIL`,

  FETCH_WADHWANI_COURSES: `${actionTypePrefix}/FETCH_WADHWANI_COURSES`,
  FETCH_WADHWANI_COURSES_SUCCESS: `${actionTypePrefix}/FETCH_WADHWANI_COURSES_SUCCESS`,
  FETCH_WADHWANI_COURSES_FAIL: `${actionTypePrefix}/FETCH_WADHWANI_COURSES_FAIL`,

  GET_COURSE_DETAIL_FROM_COURSEKEY: `${actionTypePrefix}/GET_COURSE_DETAIL_FROM_COURSEKEY`,
  GET_COURSE_DETAIL_FROM_COURSEKEY_SUCCESS: `${actionTypePrefix}/GET_COURSE_DETAIL_FROM_COURSEKEY_SUCCESS`,
  GET_COURSE_DETAIL_FROM_COURSEKEY_FAIL: `${actionTypePrefix}/GET_COURSE_DETAIL_FROM_COURSEKEY_FAIL`,
  RESET_COURSE_DETAIL_FROM_COURSEKEY: `${actionTypePrefix}/RESET_COURSE_DETAIL_FROM_COURSEKEY`,
  SET_ACTIVE_MODULE: `${actionTypePrefix}/SET_ACTIVE_MODULE`,
  SET_ACTIVE_MODULE_SUCCESS: `${actionTypePrefix}/SET_ACTIVE_MODULE_SUCCESS`,

  FETCH_MULTIPLE_LESSON_AI_MAPPING: `${actionTypePrefix}/FETCH_MULTIPLE_LESSON_AI_MAPPING`,
  FETCH_MULTIPLE_LESSON_AI_MAPPING_SUCCESS: `${actionTypePrefix}/FETCH_MULTIPLE_LESSON_AI_MAPPING_SUCCESS`,
  FETCH_MULTIPLE_LESSON_AI_MAPPING_FAIL: `${actionTypePrefix}/FETCH_MULTIPLE_LESSON_AI_MAPPING_FAIL`,

  FETCH_FORUM_TRENDING_TOPIC: `${actionTypePrefix}/FETCH_FORUM_TRENDING_TOPIC`,
  FETCH_FORUM_TRENDING_TOPIC_SUCCESS: `${actionTypePrefix}/FETCH_FORUM_TRENDING_TOPIC_SUCCESS`,
  FETCH_FORUM_TRENDING_TOPIC_FAIL: `${actionTypePrefix}/FETCH_FORUM_TRENDING_TOPIC_FAIL`
};

export interface IFetchForumTrendingTopicPayload {
  discourseCategorySlug: string;
  discourseId: string;
}

export interface IFetchForumTrendingTopicAction {
  type: string;
  payload: IFetchForumTrendingTopicPayload;
}

export interface ICourseTag {
  id: number;
  name: string;
}

export interface IFacultyCourses {
  certificationValidTill?: string;
  certifiedOn?: string;
  fullname: string;
  id: number;
}

export interface IFetchActivityStatus {
  userId: string;
  lessonId: string;
  sectionId: string;
  activity: IMods;
  courseId: string;
  isFinalAssessment?:boolean;
  isFromLMSEvent?:boolean;
  isCertifiedAssessment?:boolean;
}

export interface IFetchCourseDetailsAction {
  type: string;
  payload: {
    courseIds: string[];
  };
}

export interface IFetchCourseContentAction {
  type: string;
  payload: {
    courseId: string;
    studentId: string;
  };
}

export interface IFetchCourseTocAction {
  type: string;
  payload: IFetchUpdatedCourseToc;
}

export interface IGetCertificateIdPayload {
  userId: string,
  courseId: string,
  product: string,
  instanceId?: string
}
export interface IGetCertificateIdAction {
  type: string,
  payload: IGetCertificateIdPayload
}

export interface IUpdateLastVisitedTimeAction {
  type: string,
  payload: {
    userId: string,
  courseId: string
  }
}


export interface IConnectDicoursePayload {
  sso: string,
  sig: string,
  userId: string
}
export interface IConnectDicourseAction {
  type: string,
  payload: IConnectDicoursePayload
}

export interface IFtechAiLessonMappingAction {
  type: string,
  payload: {
    lessonId: string
  },
}

export interface IFtechMultipleLessonAiMappingPayload {
  lessonIds: string,
  courseId:string
}
export interface IFtechMultipleLessonAiMappingAction {
  type: string,
  payload: IFtechMultipleLessonAiMappingPayload
}

export interface IFetchUpdatedCourseToc {
  courseId: string,
  studentId: string,
  isFromLMSEvent?: boolean
}


export interface IFetchOpenCourseListPayload {
  userId?: string;
  page?: number;
  limit?: number;
  unEnrolledCourses?: boolean;
  language?: string;
  instituteId?: string;
}
export interface IFetchOpenCourseListAction {
  type: string;
  payload: IFetchOpenCourseListPayload
}

export interface IEnrollCoursePayload {
  courseRootId?: string;
  userId?: string;
  courseId?: string;
  batchId?: number;
  pageNumber?: number;
  nonPaginated?: boolean;
  limit?: number;
}

export interface IEnrollCourseAction {
  type: string;
  payload: IEnrollCoursePayload
}

export interface IEnrollToCourseSuccessPayload{
  responseData:any;
  courseId:string
};

export interface IFetchForumDetailsByIdPayload {
    courseKey:string
}

export interface IFetchForumDetailsByIdAction {
  type:string;
  payload:IFetchForumDetailsByIdPayload
}

export interface IFetchInstituteCourse {
  pageNumber: number;
  limit: number;
  instituteIds: string[];
  language?: string;
  userId: string;
}

export interface IFetchInstituteCourseAction {
  type: string;
  payload: IFetchInstituteCourse;
}

export interface IDiscourseSyncToGroupPayload {
  userId: string,
  email: string,
  username: string,
  groups: string,
  role: string;
  forumId: string,
}

export interface IDiscourseSyncToGroupAction {
  type: string,
  payload: IDiscourseSyncToGroupPayload
}

export interface IFetchCourseDetailFromCourseKey {
  userId: string;
  courseKey?: string;
  courseRootId?: string;
}

export interface IFetchCourseDetailFromCourseKeyAction {
  type:string;
  payload:IFetchCourseDetailFromCourseKey
}

export const fetchMultipleCourseDetails = (
  courseIds: string[]
): IFetchCourseDetailsAction => {
  return {
    type: courseActions.FETCH_MULTIPLE_COURSES_DETAILS,
    payload: { courseIds },
  };
};

export const fetchMultipleCourseDetailsSuccess = (
  courseData: CourseModel[]
) => {
  return {
    type: courseActions.FETCH_MULTIPLE_COURSES_DETAILS_SUCCESS,
    payload: {
      courseData,
    },
  };
};

export const fetchMultipleCourseDetailsFailure = (error: string) => {
  return {
    type: courseActions.FETCH_MULTIPLE_COURSES_DETAILS_FAIL,
    payload: error,
  };
};

export interface IFetchCourseCompetenciesAction {
  type: string;
  payload: {
    courseId: string;
  };
}

export interface IGetCertifiedFacultyCourses {
  courseRootIds?: string;
  userIds?: string;
  pageNumber?: number;
  limit?: number;
}

export interface IFetchActivityStatusAction {
  type: string;
  payload: IFetchActivityStatus;
}

export interface IFetchCertifiedFacultyCourseAction {
  type: string;
  payload: IGetCertifiedFacultyCourses;
}

export const fetchCourseCompetencies = (
  courseId: string
): IFetchCourseCompetenciesAction => {
  return {
    type: courseActions.FETCH_COURSE_COMPETENCIES,
    payload: { courseId },
  };
};

export const fetchCourseCompetenciesSuccess = (
  courseCompetencies: CourseCompetencyModel[]
) => {
  return {
    type: courseActions.FETCH_COURSE_COMPETENCIES_SUCCESS,
    payload: {
      courseCompetencies,
    },
  };
};

export const fetchCourseCompetenciesFailure = (error: string) => {
  return {
    type: courseActions.FETCH_COURSE_COMPETENCIES_FAIL,
    payload: error,
  };
};

// courseTags
export const fetchCourseTags = (courseId: string): IFetchCourseCompetenciesAction => {
  return {
    type: courseActions.FETCH_COURSE_TAGS,
    payload: { courseId },
  };
};

export const fetchCourseTagsSuccess = (courseTags: ICourseTag[]) => {
  return {
    type: courseActions.FETCH_COURSE_TAGS_SUCCESS,
    payload: {
      courseTags,
    },
  };
};

export const fetchCourseTagsFailure = (error: string) => {
  return {
    type: courseActions.FETCH_COURSE_TAGS_FAIL,
    payload: error,
  };
};

// faculty courses
export const fetchFacultyCourses = (facultyId: string) => {
  return {
    type: courseActions.FETCH_FACULTY_COURSES,
    payload: facultyId,
  };
};

export const fetchFacultyCoursesSuccess = (payload: IFacultyCourses[]) => {
  return {
    type: courseActions.FETCH_FACULTY_COURSES_SUCCESS,
    payload,
  };
};

export const fetchFacultyCoursesFail = (payload: string) => {
  return {
    type: courseActions.FETCH_FACULTY_COURSES_FAIL,
    payload,
  };
};

export const fetchEnrolledCourses = (payload: IFetchEnrolledCourses) => {
  return {
    type: courseActions.FETCH_ENROLLED_COURSES,
    payload
  };
};

export const fetchEnrolledCoursesSuccess = (payload) => {
  return {
    type: courseActions.FETCH_ENROLLED_COURSES_SUCCESS,
    payload
  };
};

export const fetchEnrolledCoursesFail = (error: string , status:string) => {
  return {
    type: courseActions.FETCH_ENROLLED_COURSES_FAIL,
    payload: {error,status}
  };
};

export const fetchCourseActivityPercentage = (payload: IFetchCourseActivity) => {
  return {
    type: courseActions.FETCH_COURSE_ACTIVITY_PERCENTAGE,
    payload
  };
};

export const fetchCourseActivityPercentageSuccess = (payload) => {
  return {
    type: courseActions.FETCH_COURSE_ACTIVITY_PERCENTAGE_SUCCESS,
    payload
  };
};

export const fetchCourseActivityPercentageFail = (error: string) => {
  return {
    type: courseActions.FETCH_COURSE_ACTIVITY_PERCENTAGE_FAIL,
    payload: error
  };
};

export const fetchCourseContent = (
  studentId: string,
  courseId: string
): IFetchCourseContentAction => {
  return {
    type: courseActions.FETCH_COURSE_CONTENT,
    payload: { courseId, studentId },
  };

};

export const fetchCourseContentSuccess = (payload: CourseToc) => {
  return {
    type: courseActions.FETCH_COURSE_CONTENT_SUCCESS,
    payload
  };
};

export const fetchCourseContentFailure = (payload) => {
  return {
    type: courseActions.FETCH_COURSE_CONTENT_FAIL,
    payload,
  };
};

// moodle lms session data
export const fetchLmsSession = () => {
  return {
    type: courseActions.FETCH_LMS_SESSION
  };
};

export const fetchLmsSessionSuccess = (payload: ILMSSession) => {
  return {
    type: courseActions.FETCH_LMS_SESSION_SUCCESS,
    payload,
  };
};

export const fetchLmsSessionFail = (payload: string) => {
  return {
    type: courseActions.FETCH_LMS_SESSION_FAIL,
    payload,
  };
};

export const fetchActivityStatus = (payload: IFetchActivityStatus): IFetchActivityStatusAction => {
  return {
    type: courseActions.FETCH_ACTIVITY_STATUS,
    payload
  };
};

export const fetchActivityStatusSuccess = (payload) => {
  return {
    type: courseActions.FETCH_ACTIVITY_STATUS_SUCCESS,
    payload
  };
};
export const fetchActivityStatusFail = (error: string) => {
  return {
    type: courseActions.FETCH_ACTIVITY_STATUS_FAIL,
    payload: {
      error
    }
  };
};

export const getCertificateId = (payload:IGetCertificateIdPayload): IGetCertificateIdAction => {
  return {
    type: courseActions.GET_CERTIFICATE_ID,
    payload
  }
}

export const getCertificateIdSuccess = (responseData) => {
  return {
    type: courseActions.GET_CERTIFICATE_ID_SUCCESS,
    payload: responseData
  }
}

export const getCertificateIdFail = (error: string) => {
  return {
    type: courseActions.GET_CERTIFICATE_ID_FAIL,
    payload: {
      error
    }
  };
};

export const connectDiscourse = (payload: IConnectDicoursePayload): IConnectDicourseAction => {
  return {
    type: courseActions.CONNECT_DISCOURSE,
    payload
  }
}

export const connectDiscourseSuccess = (response: any) => {
  return {
    type: courseActions.CONNECT_DISCOURSE_SUCCESS,
    payload: response
  }
}

export const connectDiscourseFail = (error: string) => {
  return {
    type: courseActions.CONNECT_DISCOURSE_FAIL,
    payload: error
  }
}

export const updateLastVisitedTime = (userId: string, courseId: string): IUpdateLastVisitedTimeAction => {
  return {
    type: courseActions.UPDATE_LAST_VISITED_TIME,
    payload: {
      userId,
      courseId
    }
  }
}

export const updateLastVisitedTimeSuccess = (response: any) => {
  return {
    type: courseActions.UPDATE_LAST_VISITED_TIME_SUCCESS,
    payload: response
  }
}

export const updateLastVisitedTimeFail = (error: string) => {
  return {
    type: courseActions.UPDATE_LAST_VISITED_TIME_FAIL,
    payload: error
  }
}

export const fetchUpdatedCourseToc = (payload:IFetchUpdatedCourseToc) => {
  return {
    type: courseActions.FETCH_UPDATED_COURSE_TOC,
    payload
  }
}

export const fetchAiLessonMapping = (lessonId: string):IFtechAiLessonMappingAction => {
  return {
    type: courseActions.FETCH_AI_LESSON_MAPPING,
    payload: {
      lessonId
    }
  }
}

export const fetchAiLessonMappingSuccess = (responseData) => {
  return {
    type: courseActions.FETCH_AI_LESSON_MAPPING_SUCCESS,
    payload: responseData
  }
}

export const fetchAiLessonMappingFail = (error: string) => {
  return {
    type: courseActions.FETCH_AI_LESSON_MAPPING_FAIL,
    payload: {
      error
    }
  };
};
export const fetchCertifiedFacultyCourses = (payload: IGetCertifiedFacultyCourses) => {
  return {
      type: courseActions.FETCH_CERTIFIED_FACULTY_COURSES,
      payload,
  }
}

export const fetchCertifiedFacultyCoursesSuccess = (payload) => {
  return {
      type: courseActions.FETCH_CERTIFIED_FACULTY_COURSES_SUCCESS,
      payload,
  }
}

export const fetchCertifiedFacultyCoursesFailure = (error: string) => {
  return {
      type: courseActions.FETCH_CERTIFIED_FACULTY_COURSES_FAIL,
      payload: error,
  }
}

// latest courses by tags
export const fetchLatestCoursesByTags = (payload: IGetCertifiedFacultyCourses) => {
  return {
      type: courseActions.FETCH_LATEST_COURSES_BY_TAGS,
      payload,
  }
}

export const fetchLatestCoursesByTagsSuccess = (payload) => {
  return {
      type: courseActions.FETCH_LATEST_COURSES_BY_TAGS_SUCCESS,
      payload,
  }
}

export const fetchLatestCoursesByTagsFailure = (error: string) => {
  return {
      type: courseActions.FETCH_LATEST_COURSES_BY_TAGS_FAIL,
      payload: error,
  }
}


export const fetchOpenCourseList = (
  payload: IFetchOpenCourseListPayload): IFetchOpenCourseListAction => {
  return {
      type: courseActions.FETCH_OPEN_COURSES_LIST,
      payload,
  };
};

export const fetchOpenCourseListSuccess = (
  courseList: CourseModel[], totalCount: number, instituteId?: string, languageCode?: string
) => {
  return {
      type: courseActions.FETCH_OPEN_COURSES_LIST_SUCCESS,
      payload: {
          courseList, totalCount, instituteId, languageCode
      },
  };
};

export const fetchOpenCourseListFailure = (error: string) => {
  return {
      type: courseActions.FETCH_OPEN_COURSES_LIST_FAIL,
      payload: error,
  };
};


export const enrollToCourse = (
  payload: IEnrollCoursePayload): IEnrollCourseAction => {
  return {
      type: courseActions.ENROLL_TO_COURSE,
      payload,
  };
};

export const enrollToCourseSuccess = (payload:IEnrollToCourseSuccessPayload) => {
  return {
      type: courseActions.ENROLL_TO_COURSE_SUCCESS,
      payload
  };
};

export const enrollToCourseFailure = (error: string) => {
  return {
      type: courseActions.ENROLL_TO_COURSE_FAIL,
      payload: error,
  };
};

export const fetchEnrolledCourseKeys = (payload) => {
  return {
    type: courseActions.FETCH_ENROLLED_COURSE_KEYS,
    payload
  }
}

export const fetchEnrolledCourseKeysSuccess = (payload) => {
  return {
    type: courseActions.FETCH_ENROLLED_COURSE_KEYS_SUCCESS,
    payload
  }
}

export const fetchEnrolledCourseKeysFail = (payload) => {
  return {
    type: courseActions.FETCH_ENROLLED_COURSE_KEYS_FAIL,
    payload
  }
}

export const getMicroCertificateId = (payload: IGetCertificateIdPayload): IGetCertificateIdAction => {
  return {
    type: courseActions.GET_MICRO_CERTIFICATE_ID,
    payload
  }
}

export const getMicroCertificateIdSuccess = (responseData, instanceId: string) => {
  return {
    type: courseActions.GET_MICRO_CERTIFICATE_ID_SUCCESS,
    payload: { responseData, instanceId }
  }
}

export const getMicroCertificateIdFail = (error: string, instanceId: string) => {
  return {
    type: courseActions.GET_MICRO_CERTIFICATE_ID_FAIL,
    payload: {
      error,
      instanceId
    }
  };
};

export const getForumDetailsByCourseId = (payload: IFetchForumDetailsByIdPayload): IFetchForumDetailsByIdAction => {
  return {
    type: courseActions.GET_FORUM_DETAILS_BYCOURSEID,
    payload
  }
}

export const getForumDetailsByCourseIdSuccess = (responseData, courseId: string) => {
  return {
    type: courseActions.GET_FORUM_DETAILS_BYCOURSEID_SUCCESS,
    payload: { responseData, courseId }
  }
}

export const getForumDetailsByCourseIdFail = (error: string, courseId: string) => {
  return {
    type: courseActions.GET_FORUM_DETAILS_BYCOURSEID_FAIL,
    payload: {
      error,
      courseId
    }
  };
};

export const discourseSyncToGroup = (payload: IDiscourseSyncToGroupPayload): IDiscourseSyncToGroupAction => {
  return {
    type: courseActions.DISCOURSE_SYNC_TO_GROUP,
    payload
  }
}

export const discourseSyncToGroupSuccess = (responseData) => {
  return {
    type: courseActions.DISCOURSE_SYNC_TO_GROUP_SUCCESS,
    payload: responseData
  }
}

export const discourseSyncToGroupFail = (error: string) => {
  return {
    type: courseActions.DISCOURSE_SYNC_TO_GROUP_FAIL,
    payload: error
  };
};

export const fetchDefaultOpenCourseList = (
  payload: IFetchOpenCourseListPayload): IFetchOpenCourseListAction => {
  return {
      type: courseActions.FETCH_DEFAULT_OPEN_COURSES_LIST,
      payload,
  };
};

export const fetchDefaultOpenCourseListSuccess = (
  courseList: CourseModel[], totalCount: number, instituteId?: string, languageCode?: string
) => {
  return {
      type: courseActions.FETCH_DEFAULT_OPEN_COURSES_LIST_SUCCESS,
      payload: {
          courseList, totalCount, instituteId, languageCode
      },
  };
};

export const fetchDefaultOpenCourseListFailure = (error: string) => {
  return {
      type: courseActions.FETCH_DEFAULT_OPEN_COURSES_LIST_FAIL,
      payload: error,
  };
};

export const fetchInstituteCourses = (
  payload: IFetchInstituteCourse,
): IFluxStandardAction<IFetchInstituteCourse> => {
  return {
    type: courseActions.FETCH_INSTITUTE_COURSES,
    payload,
  };
};

export const fetchInstituteCoursesSuccess = (
  payload,
): IFluxStandardAction<CourseModel[]> => {
  return {
    type: courseActions.FETCH_INSTITUTE_COURSES_SUCCESS,
    payload,
  };
};

export const fetchInstituteCoursesFail = (
  error: string,
): IFluxStandardAction<string> => {
  return {
    type: courseActions.FETCH_INSTITUTE_COURSES_FAIL,
    payload: error,
  };
};

export const fetchWadhwaniCourses = (
  payload: IFetchInstituteCourse,
): IFluxStandardAction<IFetchInstituteCourse> => {
  return {
    type: courseActions.FETCH_WADHWANI_COURSES,
    payload,
  };
};

export const fetchWadhwaniCoursesSuccess = (
  payload,
): IFluxStandardAction<CourseModel[]> => {
  return {
    type: courseActions.FETCH_WADHWANI_COURSES_SUCCESS,
    payload,
  };
};

export const fetchWadhwaniCoursesFail = (
  error: string,
): IFluxStandardAction<string> => {
  return {
    type: courseActions.FETCH_WADHWANI_COURSES_FAIL,
    payload: error,
  };
};

export const getCourseDetailFromCourseKey = (payload: IFetchCourseDetailFromCourseKey): IFetchCourseDetailFromCourseKeyAction => {
  return {
    type: courseActions.GET_COURSE_DETAIL_FROM_COURSEKEY,
    payload,
  }
}

export const getCourseDetailFromCourseKeySuccess = (payload: CourseModel) => {
  return {
    type: courseActions.GET_COURSE_DETAIL_FROM_COURSEKEY_SUCCESS,
    payload,
  }
}

export const getCourseDetailFromCourseKeyFail = (payload) => {
  return {
    type: courseActions.GET_COURSE_DETAIL_FROM_COURSEKEY_FAIL,
    payload,
  }
}

export const resetCourseDetailFromCourseKey = () => {
  return {
    type: courseActions.RESET_COURSE_DETAIL_FROM_COURSEKEY
  }
}
  
export const setActiveModule = (moduleKey: number): {type: string, payload: number} => {
  return{
    type: courseActions.SET_ACTIVE_MODULE,
    payload: moduleKey
  }
}

export const setActiveModuleSuccess = (moduleKey: number): {type: string, payload: number} => {
  return{
    type: courseActions.SET_ACTIVE_MODULE_SUCCESS,
    payload: moduleKey
  }
}

export const fetchMultipleLessonAiMapping = (payload: IFtechMultipleLessonAiMappingPayload): IFtechMultipleLessonAiMappingAction => {
  return {
    type: courseActions.FETCH_MULTIPLE_LESSON_AI_MAPPING,
    payload
  }
}

export const fetchMultipleLessonAiMappingSuccess = (responseData) => {
  return {
    type: courseActions.FETCH_MULTIPLE_LESSON_AI_MAPPING_SUCCESS,
    payload: responseData
  }
}

export const fetchMultipleLessonAiMappingFail = (error: string) => {
  return {
    type: courseActions.FETCH_MULTIPLE_LESSON_AI_MAPPING_FAIL,
    payload: {
      error
    }
  };
};

export const fetchForumTrendingTopic = (payload:IFetchForumTrendingTopicPayload) =>{
  return{
    type:courseActions.FETCH_FORUM_TRENDING_TOPIC,
    payload
  }
}

export const fetchForumTrendingTopicSuccess = (response:any,discourseCategorySlug:string )=>{
  return{
    type: courseActions.FETCH_FORUM_TRENDING_TOPIC_SUCCESS,
    payload:{
      response,
      discourseCategorySlug
    }
  }
}

export const fetchForumTrendingTopicFail = (error:string,discourseCategorySlug:string) =>{
  return{
    type: courseActions.FETCH_FORUM_TRENDING_TOPIC_FAIL,
    payload:{
      error,
      discourseCategorySlug
    }
  }
}

/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import "@babel/polyfill";
import "reflect-metadata";

// Import all the third party stuff
import React, { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
// Import root app
import RootContainer from "@containers/Root/RootContainer";
import * as OfflinePluginRuntime from "offline-plugin/runtime";

// Load the favicon and the .htaccess file
// import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import "!file-loader?name=[name].[ext]!./assets/Images/WS_ICON.png";
import "!file-loader?name=[name].[ext]!./firebase-messaging-sw.js";
import { i18n } from "@translations/i18n";
import "file-loader?name=.htaccess!./.htaccess";
import { I18nextProvider } from "react-i18next";

import { WebStoreProviderService } from "@store/WebStoreProviderService";
import { AnalyticsService } from "@webServices/AnalyticsService";

import { MSClarityService } from "@webServices/MSClarityService";
import { PersistGate } from "redux-persist/integration/react";
import { WFSpinner } from "./components";
import { SentryService } from "@webServices/SentryService";
import { TagManagerService } from "@webServices/TagManagerService";

import "./App.less";
import "@theme/index.less";
import "antd/dist/reset.css";

const store = WebStoreProviderService.getStore();
const persistor = WebStoreProviderService.getPersistor();

const MOUNT_NODE = document.getElementById("app");
const root = createRoot(MOUNT_NODE);

const initializeAnalytics = () => {
  MSClarityService.init();
  AnalyticsService.init();
};


const initializeGTM = () => {
  TagManagerService.init();
}

const initiateSentryServices = () => {
  SentryService.init();
};


const render = () => {
  initializeAnalytics();
  initiateSentryServices();
  initializeGTM();

  root.render(
    // @ts-ignore
    <Provider store={store}>
      <PersistGate
        loading={<WFSpinner showFullPage={true} size={"large"} />}
        onBeforeLift={null}
        persistor={persistor}
      >
        <Suspense fallback={null}>
          <I18nextProvider i18n={i18n}>
              <RootContainer />
          </I18nextProvider>
        </Suspense>
      </PersistGate>
    </Provider>
  );
};

render();

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === "production") {
  OfflinePluginRuntime.install({
    onUpdateReady: () => {
      OfflinePluginRuntime.applyUpdate();
    },
    onUpdated: () => {
      (window as any).swUpdate = true;
      window.location.reload();
    },
  });
}
import { ICollaborateActivityJobRoles } from "@common/redux/batch/actions";
import { JsonProperty } from "json-object-mapper";

export class ValidateJobRoleModel {
  @JsonProperty({ name: "jobRole", required: false })
  private jobRole?: string = "";

  @JsonProperty({ name: "valid", required: false })
  private valid?: string = "";

  @JsonProperty({ name: "type", required: false })
  private type?: string = "";

  public getJobRole(): string {
    return this.jobRole;
  }

  public getValid(): string {
    return this.valid;
  }

  public getType(): string {
    return this.type;
  }
}
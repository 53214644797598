import { call, ForkEffect, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { SlotBookingRepository } from '@common/domain/repositories/SlotBookingRepository';

import { getAvailableSlotsFailure, getAvailableSlotsSuccess, getSlotBookingFailure, getSlotBookingSuccess, IAvailableSlotsPayload, ICreateSlotBookingAction, IGetSlotBookingAction, IUpdateSlotBookingAction, SlotBookingActions } from './actions';


export function* getSlotBookingSaga(action: IGetSlotBookingAction): any {
    const { userId } = action.payload;
    try {
        const result: any = yield call(SlotBookingRepository.fetchSlotBooking, userId);
        yield put(getSlotBookingSuccess(result?.getGroups()));
    } catch (e) {
        yield put(getSlotBookingFailure(e?.message));
    }
}

export function* createSlotBookingSaga(action: ICreateSlotBookingAction): any {
    const { userId, slotBooking } = action.payload;
    try {
        const result: any = yield call(SlotBookingRepository.createSlotBooking, userId, slotBooking);
        yield put(getSlotBookingSuccess(result?.getGroups()));
    } catch (e) {
        yield put(getSlotBookingFailure(e?.message));
    }
}

export function* updateSlotBookingSaga(action: IUpdateSlotBookingAction): any {
    const { userId, slotBooking } = action.payload;
    try {
        const result: any = yield call(SlotBookingRepository.updateSlotBooking, userId, slotBooking);
        yield put(getSlotBookingSuccess(result?.getGroups()));
    } catch (e) {
        yield put(getSlotBookingFailure(e?.message));
    }
}

export function* getAvailableSlots(action: {type: string; payload: IAvailableSlotsPayload}): any {
    const { userId, date } = action.payload;
    try {
        const result: any = yield call(SlotBookingRepository.fetchAvailableSlots, userId, date);
        yield put(getAvailableSlotsSuccess(result));
    } catch (e) {
        yield put(getAvailableSlotsFailure(e?.message));
    }
}


export function* watchSlotBookingSaga(): Generator<ForkEffect> {
    yield takeLatest(SlotBookingActions.FETCH_SLOT_BOOKING, getSlotBookingSaga);
    yield takeEvery(SlotBookingActions.CREATE_SLOT_BOOKING, createSlotBookingSaga);
    yield takeEvery(SlotBookingActions.UPDATE_SLOT_BOOKING, updateSlotBookingSaga);
    yield takeLatest(SlotBookingActions.FETCH_AVAILABLE_SLOTS, getAvailableSlots);
}

import { ICollaborateActivityJobRoles, IJob } from "@common/redux/batch/actions";
import { JsonProperty } from "json-object-mapper";

interface IParticipants {
  role: string;
  description: string;
}
interface IGeneratedActivity {
  activityName: string;
  overview: string;
  objective: string;
  setting: string;
  participants: IParticipants[];
  background?: string[];
  activityInstructions?: string[];
  discussionQuestions?: string[];
  questions?: any;
  caseStudy?: string;
  caseStudyName?: string;
}

export class CollaborateActivityDetailsModel {
  @JsonProperty({ name: "activityType", required: true })
  private activityType?: string = "";

  @JsonProperty({ name: "lessonKey", required: false })
  private lessonKey?: string = "";

  @JsonProperty({ name: "lessonName", required: false })
  private lessonName?: string = "";

  @JsonProperty({ name: "job", required: false })
  private job?: IJob = undefined;

  @JsonProperty({ name: "level", required: false })
  private level?: string = "";

  @JsonProperty({ name: "generatedActivity", required: false })
  private generatedActivity?: IGeneratedActivity = undefined;

  @JsonProperty({ name: "language", required: false })
  private language?: string = "";

  @JsonProperty({ name: "country", required: false })
  private country?: string = "";

  @JsonProperty({ name: "deleted", required: false })
  private deleted?: boolean = false;

  @JsonProperty({ name: "createdAt", required: false })
  private createdAt?: string = "";

  @JsonProperty({ name: "updatedAt", required: false })
  private updatedAt?: string = "";

  @JsonProperty({ name: "id", required: false })
  private id?: string = "";

  public getActivityType(): string {
    return this.activityType;
  }

  public getLessonKey(): string {
    return this.lessonKey;
  }

  public getLessonName(): string {
    return this.lessonName;
  }

  public getJob(): IJob {
    return this.job;
  }

  public getLevel(): string {
    return this.level;
  }

  public getGeneratedActivity(): IGeneratedActivity {
    return this.generatedActivity;
  }

  public getLanguage(): string {
    return this.language;
  }

  public getCountry(): string {
    return this.country;
  }

  public getDeleted(): boolean {
    return this.deleted;
  }

  public getCreatedAt(): string {
    return this.createdAt;
  }

  public getUpdatedAt(): string {
    return this.updatedAt;
  }

  public getId(): string {
    return this.id;
  }
}
import { ICollaborateActivityJobRoles, ICollborateJobRole } from "@common/redux/batch/actions";
import { JsonProperty } from "json-object-mapper";

interface IActivityType {
  groupcasestudy: any;
  grouproleplay: any;
  pairplay: any;
}

export class CollaborateActivityModel {
  @JsonProperty({ name: "level", required: false })
  private level?: string = "";

  @JsonProperty({ name: "jobs", required: false })
  private jobs?: ICollborateJobRole[] = [];

  @JsonProperty({ name: "active", required: false })
  private active?: IActivityType = undefined;

  @JsonProperty({ name: "inactive", required: false })
  private inactive?: IActivityType = undefined;

  @JsonProperty({ name: "isInactiveHaveData", required: false })
  private isInactiveHaveData?: boolean = false;

  public getLevel(): string {
    return this.level;
  }

  public getJobRoles(): ICollborateJobRole[] {
    return this.jobs;
  }

  public getActive(): IActivityType {
    return this.active;
  }

  public getInactive(): IActivityType {
    return this.inactive;
  }

  public getIsInactiveHaveData(): boolean {
    return this.isInactiveHaveData;
  }
}

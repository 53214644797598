import {
    deserializeJsonObj, deserializeJsonObjCollection,
} from "@utils/ObjectMapperUtil";

import { APIv2, GLOBAL_BASE_URL_COMMON } from "@common/network/constants";

import { ApiClient } from "@common/network/client";
import { SlotBookingModel } from "../models/SlotBookingModel";
import moment from "moment";
import { getTimeZone } from "@utils/DeviceInfoUtils";

const ENDPOINTS = {
    fetchSlotBooking: (userId: string) => `${GLOBAL_BASE_URL_COMMON}${APIv2}/schedulingservice/user/${userId}/slots`,
    createSlotBooking: (userId: string) => `${GLOBAL_BASE_URL_COMMON}${APIv2}/schedulingservice/user/${userId}/slots`,
    updateSlotBooking: (userId: string, groupId: string) => `${GLOBAL_BASE_URL_COMMON}${APIv2}/schedulingservice/user/${userId}/group/${groupId}/slot`,
    availableslots: (userId: string) => `${GLOBAL_BASE_URL_COMMON}${APIv2}/schedulingservice/user/${userId}/availableSlots`,
};

class SlotBookingRepository {
    private apiClient: any;

    constructor(apiClient: any) {
        this.apiClient = apiClient;
    }

    public fetchSlotBooking = async (userId: string) => {
        const response = await this.apiClient.get(
            ENDPOINTS.fetchSlotBooking(userId)
        );
        const slotBooking = deserializeJsonObj(
            response,
            SlotBookingModel,
            "fetchSlots"
        );
        return slotBooking;
    };


    public createSlotBooking = async (userId: string, payload: any) => {
        const timezone = getTimeZone();
        payload.availability = payload?.availability.map((item: any) => {
            return {
                day: item?.day,
                intervals: item?.intervals?.map((interval: any) => {
                    return {
                        startTime: interval?.startTime,
                        endTime: interval?.endTime,
                    };
                }),
            };
        });
        const response = await this.apiClient.post(
            ENDPOINTS.createSlotBooking(userId),
            payload
        );
        const slotBooking = deserializeJsonObj(
            response,
            SlotBookingModel,
            "fetchSlots"
        );
        return slotBooking;
    }

    public updateSlotBooking = async (userId: string, payload: any) => {
        const timezone = getTimeZone();
        payload.availability = payload.availability.map((item: any) => {
            return {
                day: item.day,
                intervals: item?.intervals?.map((interval: any) => {
                    return {
                        startTime: interval.startTime,
                        endTime: interval.endTime,
                    };
                }),
            };
        });
        const response = await this.apiClient.patch(
            ENDPOINTS.updateSlotBooking(userId, payload.groupId),
            payload
        );
        const slotBooking = deserializeJsonObj(
            response,
            SlotBookingModel,
            "fetchSlots"
        );
        return slotBooking;
    }


    public getTimezoneOffset = (timeZone, date = new Date()) => {
        const tz = date.toLocaleString("en", { timeZone, timeStyle: "long" }).split(" ").slice(-1)[0];
        const dateString = date.toString();
        const offset = Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);
        return offset / 3600000;
    }

    public fetchAvailableSlots = async (userId: string, actualDate?: string) => {
        const lastDay = moment(actualDate).subtract(1, "day").format("YYYY-MM-DD");
        const nextDay = moment(actualDate).add(1, "day").format("YYYY-MM-DD");
        const dates = [lastDay, actualDate, nextDay];
        const slots = [];
        let actualDateResponse = null;
        for (const date of dates) {
            let url = ENDPOINTS.availableslots(userId);
            if (date) {
                url = `${url}?date=${date}`;
            }
            const response = await this.apiClient.get(
                url
            );
            if (date === actualDate) {
                actualDateResponse = response;
            }
            const offset = this.getTimezoneOffset(response.timeZone);
            const currentTimezone: number = parseFloat(getTimeZone());
            const diff = currentTimezone - offset;
            const addHours = Math.floor(diff);


            slots.push(...response.slots.map((slot: any) => {
                const [hour, minute] = slot.start.split(":") ?? [];
                const startTime = moment(`${date} ${hour}:${minute}`, 'YYYY-MM-DD HH:mm').add(diff, 'hour');
                const [endHour, endMinute] = slot.end.split(":");
                const endTime = moment(`${date} ${endHour}:${endMinute}`, 'YYYY-MM-DD HH:mm').add(diff, 'hour');
                return {
                    ...slot,
                    startTime,
                    endTime,
                };
            }))
        }
        const currentTimezone = getTimeZone();
        const startTime = moment(actualDate).utcOffset(currentTimezone).startOf('day');
        const endTime = moment(actualDate).utcOffset(currentTimezone).add(1, 'day').startOf('day');
        const currentTime = moment().utcOffset(currentTimezone).add(1, 'hour');
        const finalSlots = slots.filter((slot: any) => {
            return slot?.startTime.utcOffset(currentTimezone).isSameOrAfter(currentTime) && slot?.startTime.utcOffset(currentTimezone).isSameOrAfter(startTime) && slot?.endTime.utcOffset(currentTimezone).isSameOrBefore(endTime);
        });
        return {
            ...actualDateResponse,
            slots: finalSlots.map((slot: any) => {
                return {
                    ...slot,
                    startTime: moment(slot?.startTime).local().format("HH:mm"),
                    endTime: moment(slot?.endTime).local().format("HH:mm"),
                };
            }),
        }
    }
}



const slotBookingRepository = new SlotBookingRepository(ApiClient);

export { slotBookingRepository as SlotBookingRepository };

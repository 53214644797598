import { IInstituteAdminState } from '@redux/instituteAdmin/interface';
import { instituteAdminActions } from '@redux/instituteAdmin/action';

export const initialState = {
    organisationId: [],
    instituteId: '',
    instituteData: {},
    organisationData: [],
    organisationDetails: [],
    courseIds: [],
    coursesData: [],
    openCoursesData: [],
    instituteAdminDashboardData: [],
    instituteAdminDashboardOpenCourseData: [],
    facultyIds: [],
    facultyData: [],
    lastRefreshedDate: '',
    totalStudentsEnrolled: 0,
    totalStudentsEnrolledInCourses: 0,
    loaders: {
        organisationId: false,
        instituteId: false,
        instituteData: false,
        organisationData: false,
        organisationDetails: false,
        courseIds: false,
        coursesData: false,
        openCoursesData: false,
        instituteAdminDashboardData: false,
        instituteAdminDashboardOpenCourseData: false,
        facultyIds: false,
        facultyData: false,
        lastRefreshedDate: false,
        totalStudentsEnrolled: false,
        totalStudentsEnrolledInCourses: false
    },
    errors: {
        organisationId: '',
        instituteId: '',
        instituteData: '',
        organisationData: '',
        organisationDetails: '',
        courseIds: '',
        coursesData: '',
        openCoursesData: '',
        instituteAdminDashboardData: '',
        instituteAdminDashboardOpenCourseData: '',
        facultyIds: '',
        facultyData: '',
        lastRefreshedDate: '',
        totalStudentsEnrolled: '',
        totalStudentsEnrolledInCourses: ''
    },
};


export const instituteAdminReducer = (
    state: IInstituteAdminState = initialState,
    action: { type: string; payload: any }
) => {
    switch (action.type) {
        case instituteAdminActions.FETCH_ORGANISATION_ADMIN_ID:
            return {
                ...state,
                organisationId: [],
                loaders: { ...state.loaders, organisationId: true },
                errors: { ...state.errors, organisationId: '' },
            };
        case instituteAdminActions.FETCH_ORGANISATION_ADMIN_ID_SUCCESS:
            return {
                ...state,
                organisationId: action.payload,
                loaders: { ...state.loaders, organisationId: false },
            };
        case instituteAdminActions.FETCH_ORGANISATION_ADMIN_ID_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, organisationId: false },
                errors: { ...state.errors, organisationId: action.payload },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_ID:
            return {
                ...state,
                loaders: { ...state.loaders, instituteId: true },
                errors: { ...state.errors, instituteId: '' },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_ID_SUCCESS:
            return {
                ...state,
                instituteId: action.payload,
                loaders: { ...state.loaders, instituteId: false },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_ID_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, instituteId: false },
                errors: { ...state.errors, instituteId: action.payload },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_DATA:
            return {
                ...state,
                loaders: { ...state.loaders, instituteData: true },
                errors: { ...state.errors, instituteData: '' },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_DATA_SUCCESS:
            return {
                ...state,
                instituteData: action.payload,
                loaders: { ...state.loaders, instituteData: false },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_DATA_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, instituteData: false },
                errors: { ...state.errors, instituteData: action.payload },
            };

            case instituteAdminActions.FETCH_ORGANISATION_ADMIN_DATA:
                return {
                    ...state,
                    organisationData: [],
                    loaders: { ...state.loaders, organisationData: true },
                    errors: { ...state.errors, organisationData: '' },
                };
            case instituteAdminActions.FETCH_ORGANISATION_ADMIN_DATA_SUCCESS:
                return {
                    ...state,
                    organisationData: action.payload,
                    loaders: { ...state.loaders, organisationData: false },
                };
            case instituteAdminActions.FETCH_ORGANISATION_ADMIN_DATA_FAIL:
                return {
                    ...state,
                    loaders: { ...state.loaders, organisationData: false },
                    errors: { ...state.errors, organisationData: action.payload },
                };
            case instituteAdminActions.FETCH_ORGANISATION_DETAILS:
                return {
                    ...state,
                    organisationDetails: [],
                    loaders: { ...state.loaders, organisationDetails: true },
                    errors: { ...state.errors, organisationDetails: '' },
                };
            case instituteAdminActions.FETCH_ORGANISATION_DETAILS_SUCCESS:
                return {
                    ...state,
                    organisationDetails: action.payload,
                    loaders: { ...state.loaders, organisationDetails: false },
                };
            case instituteAdminActions.FETCH_ORGANISATION_DETAILS_FAIL:
                return {
                    ...state,
                    loaders: { ...state.loaders, organisationDetails: false },
                    errors: { ...state.errors, organisationDetails: action.payload },
                };
        case instituteAdminActions.FETCH_COURSES_IDS:
            return {
                ...state,
                loaders: { ...state.loaders, courseIds: true },
                errors: { ...state.errors, courseIds: '' },
            };
        case instituteAdminActions.FETCH_COURSES_IDS_SUCCESS:
            return {
                ...state,
                courseIds: action.payload,
                loaders: { ...state.loaders, courseIds: false },
            };
        case instituteAdminActions.FETCH_COURSES_IDS_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, courseIds: false },
                errors: { ...state.errors, courseIds: action.payload },
            };
        case instituteAdminActions.FETCH_COURSES_DATA:
            return {
                ...state,
                loaders: { ...state.loaders, coursesData: true },
                errors: { ...state.errors, coursesData: '' },
            };
        case instituteAdminActions.FETCH_COURSES_DATA_SUCCESS:
            return {
                ...state,
                coursesData: action.payload,
                loaders: { ...state.loaders, coursesData: false },
            };
        case instituteAdminActions.FETCH_COURSES_DATA_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, coursesData: false },
                errors: { ...state.errors, coursesData: action.payload },
            };   
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_DASHBOARD_DATA:
            return {
                ...state,
                loaders: { ...state.loaders, instituteAdminDashboardData: true },
                errors: { ...state.errors, instituteAdminDashboardData: '' },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                instituteAdminDashboardData: action.payload,
                loaders: { ...state.loaders, instituteAdminDashboardData: false },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_DASHBOARD_DATA_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, instituteAdminDashboardData: false },
                errors: { ...state.errors, instituteAdminDashboardData: action.payload },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_OPEN_COURSE_DATA:
            return {
                ...state,
                loaders: { ...state.loaders, instituteAdminDashboardOpenCourseData: true },
                errors: { ...state.errors, instituteAdminDashboardOpenCourseData: '' },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_DASHBOARD_OPEN_COURSE_DATA_SUCCESS:
            return {
                ...state,
                instituteAdminDashboardOpenCourseData: action.payload,
                loaders: { ...state.loaders, instituteAdminDashboardOpenCourseData: false },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_DASHBOARD_OPEN_COURSE_DATA_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, instituteAdminDashboardOpenCourseData: false },
                errors: { ...state.errors, instituteAdminDashboardOpenCourseData: action.payload },
            };   
        case instituteAdminActions.FETCH_FACULTY_IDS:
            return {
                ...state,
                loaders: { ...state.loaders, facultyIds: true },
                errors: { ...state.errors, facultyIds: '' },
            };
        case instituteAdminActions.FETCH_FACULTY_IDS_SUCCESS:
            return {
                ...state,
                facultyIds: action.payload,
                loaders: { ...state.loaders, facultyIds: false },
            };
        case instituteAdminActions.FETCH_FACULTY_IDS_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, facultyIds: false },
                errors: { ...state.errors, facultyIds: action.payload },
            };
        case instituteAdminActions.FETCH_FACULTY_DATA:
            return {
                ...state,
                loaders: { ...state.loaders, facultyData: true },
                errors: { ...state.errors, facultyData: '' },
            };
        case instituteAdminActions.FETCH_FACULTY_DATA_SUCCESS:
            return {
                ...state,
                facultyData: action.payload,
                loaders: { ...state.loaders, facultyData: false },
            };
        case instituteAdminActions.FETCH_FACULTY_DATA_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, facultyData: false },
                errors: { ...state.errors, facultyData: action.payload },
            }; 
            
        case instituteAdminActions.FETCH_LAST_REFRESHED_DATA:
            return {
                ...state,
                loaders: { ...state.loaders, lastRefreshedDate: true },
                errors: { ...state.errors, lastRefreshedDate: '' },
            };
        case instituteAdminActions.FETCH_LAST_REFRESHED_DATA_SUCCESS:
            return {
                ...state,
                lastRefreshedDate: action.payload,
                loaders: { ...state.loaders, lastRefreshedDate: false },
            };
        case instituteAdminActions.FETCH_LAST_REFRESHED_DATA_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, lastRefreshedDate: false },
                errors: { ...state.errors, lastRefreshedDate: action.payload },
            };  
        case instituteAdminActions.FETCH_OPEN_COURSES_DATA:
            return {
                ...state,
                loaders: { ...state.loaders, openCoursesData: true },
                errors: { ...state.errors, openCoursesData: '' },
            };
        case instituteAdminActions.FETCH_OPEN_COURSES_DATA_SUCCESS:
            return {
                ...state,
                openCoursesData: action.payload,
                loaders: { ...state.loaders, openCoursesData: false },
            };
        case instituteAdminActions.FETCH_OPEN_COURSES_DATA_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, openCoursesData: false },
                errors: { ...state.errors, openCoursesData: action.payload },
            }; 
        case instituteAdminActions.FETCH_TOTAL_STUDENTS_ENROLLED:
            return {
                ...state,
                loaders: { ...state.loaders, totalStudentsEnrolled: true },
                errors: { ...state.errors, totalStudentsEnrolled: '' },
            };
        case instituteAdminActions.FETCH_TOTAL_STUDENTS_ENROLLED_SUCCESS:
            return {
                ...state,
                totalStudentsEnrolled: action.payload,
                loaders: { ...state.loaders, totalStudentsEnrolled: false },
            };
        case instituteAdminActions.FETCH_TOTAL_STUDENTS_ENROLLED_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, totalStudentsEnrolled: false },
                errors: { ...state.errors, totalStudentsEnrolled: action.payload },
            }; 
        case instituteAdminActions.FETCH_TOTAL_STUDENTS_ENROLLED_IN_COURSES:
            return {
                ...state,
                loaders: { ...state.loaders, totalStudentsEnrolledInCourses: true },
                errors: { ...state.errors, totalStudentsEnrolledInCourses: '' },
            };
        case instituteAdminActions.FETCH_TOTAL_STUDENTS_ENROLLED_IN_COURSES_SUCCESS:
            return {
                ...state,
                totalStudentsEnrolledInCourses: action.payload,
                loaders: { ...state.loaders, totalStudentsEnrolledInCourses: false },
            };
        case instituteAdminActions.FETCH_TOTAL_STUDENTS_ENROLLED_IN_COURSES_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, totalStudentsEnrolledInCourses: false },
                errors: { ...state.errors, totalStudentsEnrolledInCourses: action.payload },
            };               
        default:
            return state;
    }
};

import TagManager from 'react-gtm-module';

const GTM_ID = 'GTM-NW3QCSLH';


export class TagManagerService {

    public static init() {
        TagManager.initialize({
            gtmId: GTM_ID   // GTM ID
        });
    }

    public static pushEvent(eventName: string, eventInfo: any) {
        TagManager.dataLayer({
            dataLayer: {
                event: eventName,
                ...eventInfo
            }
        });
    }

    public static pushPageView(pageName: string) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageName: pageName
            }
        });
    }

    public static pushUserContext(userId: string) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'userContext',
                userId: userId
            }
        });
    }

    public static removeUserContext() {
        TagManager.dataLayer({
            dataLayer: {
                event: 'userContext',
                userId: null
            }
        });
    }



}

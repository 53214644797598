export const CollaborateTypes = {
    editCollaborateActivity: 'editCollaborateActivity'
};

export const activityModtype = {
    LTI: 'lti'
};

export const jobRoleStatus = {
    active: 'active',
    archived: 'archived'
};

export const jobRoleType = {
    role: 'role',
    sector: 'sector',
    jobRole: 'job_role'
};

export const jobRoleValidation = 'jobRoleValidation';

export const jovRoleValidType = {
  valid: 'yes',
  invalid: 'no'
};

export const activityTagNames = {
    activityType: 'activityType',
    externalDependency: 'ExternalDependency',
    collaborate: 'collaborate'
};

export const activityPlayType = {
    groupRolePlay: 'GroupRolePlay',
    groupCaseStudy: 'GroupCaseStudy',
    pairPlay: 'PairPlay'
};

export const activityPlayTypeAccess = {
    groupRolePlay: 'grouproleplay',
    groupCaseStudy: 'groupcasestudy',
    pairPlay: 'pairplay'
};

export const activityDetailsSections = {
    questionsDiscussion: 'questionsDiscussion',
    topicDiscussion: 'topicDiscussion'
};
import {
  deserializeJsonObj,
  deserializeJsonObjCollection,
} from "@utils/ObjectMapperUtil";

import { APIv1 } from "@common/network/constants";
import { IConnectDicoursePayload, ICourseTag, IDiscourseSyncToGroupPayload, IEnrollCoursePayload, IFacultyCourses, IFetchForumDetailsByIdPayload, IFetchForumTrendingTopicPayload, IFetchOpenCourseListPayload, IFtechMultipleLessonAiMappingPayload, IGetCertificateIdPayload, IGetCertifiedFacultyCourses } from "@common/redux/course/actions";

import { CourseModel, IEnrolledCourse, IFetchCourseActivity, IFetchEnrolledCourses, IFetchLatestCoursePayload } from "@models/Course";
import { CourseToc } from "@models/CourseToc";

import config from "@config/index";
import { ApiClient, wfcommonApiClient } from "@network/client";
import { CourseCompetencyModel } from "../models/CourseCompetency";
import Navigator from '@navigator/index';
import { NavigationUrl, URLs } from '@navigator/NavigationUrl';

const ENDPOINTS = {
  fetchCourseCompetencies: (courseId: string) => `${APIv1}/courseservice/reports/quizzes/course/${courseId}`,
  fetchCourseTags: () => `${APIv1}/organizationservice/courseTags`,
  fetchFacultyCourses: (facultyId: string) => `${APIv1}/organizationservice/batches/batchFaculties/${facultyId}/courses`,

  // New course apis
  fetchCourses: () => `${APIv1}/courseservice/courses`,
  fetchCourseById: (courseId: string) => `${APIv1}/courseservice/course/${courseId}`,
  fetchCourseRoots: () => `${APIv1}/courseservice/courseroots`,
  fetchLatestCourses: () => `${APIv1}/courseservice/courses/latest`,
  fetchEnrolledCourses: () => `${APIv1}/courseservice/courseEnrolments`,
  enrolCourses: () => `${APIv1}/courseservice/courseEnrolments`,
  courseActivityPercentage: (userId: string) => `${APIv1}/courseservice/reports/users/${userId}/activity/courses`,
  fetchCourseTOC: (studentId: string, courseId: string) => `${APIv1}/courseservice/reports/course/${studentId}/student/${courseId}/contents`,
  fetchLMSSession: () => `${APIv1}/courseservice/externalCourses/session`,
  fetchActivityStatus: (activityId: number, userId: string) => `${APIv1}/courseservice/externalCourses/activityId/${activityId}/userId/${userId}/status`,
  getCertificateId: () => `${APIv1}/certificateservice/get`,
  connectDiscourse: () => `${APIv1}/organizationservice/discourse`,
  updateLastVisitedTime: (userId: string, courseId: string) => `${APIv1}/courseservice/user/${userId}/course/${courseId}/lastVisitedtime`,
  getLessonMapping: () => `${APIv1}/courseservice/ai/lesson/mappings`,
  fetchCertifiedFacultyCourses: () => `${APIv1}/courseservice/facultyCourses`,
  fetchOpenCourseList: (userId: string) => `${APIv1}/courseservice/users/${userId}/available/openCourses`,
  enrollToCourse: () => `${APIv1}/courseservice/courseEnrolments`,
  fetchForumDetailsByCourseKey: () => `${APIv1}/organizationservice/forum`,
  discourseSyncToGroup: () => `${APIv1}/organizationservice/discourse/sync`,
  fetchEnrolledCourseKeys: (userId: string) => `${APIv1}/courseservice/user/${userId}/coursekeys`,
  fetchInstituteOpenCourses: (userId: string) =>
    `${APIv1}/courseservice/users/${userId}/institute/openCourses`,
  getCourseDetailFromCourseKey: (userId) => `${APIv1}/courseservice/users/${userId}/courses/enrolledOrLatestCourseData`,
  fetchForumTrendingTopic: () => `${APIv1}/organizationservice/discourse/trending`
};

class CourseRepository {
  private apiClient: any;
  private commonApiClient: any;
  constructor(apiClient: any, commonApiClient: any) {
    this.apiClient = apiClient;
    this.commonApiClient = commonApiClient
  }

  public fetchCourseCompetencies = async (courseId: string): Promise<CourseCompetencyModel[]> => {

    const response = await this.apiClient.get(
      ENDPOINTS.fetchCourseCompetencies(courseId));
    const courseCompetencies = deserializeJsonObjCollection(response, CourseCompetencyModel, "fetchCourseCompetencies") as CourseCompetencyModel[];
    return courseCompetencies;

  };

  public fetchCourseTags = async (courseId: string): Promise<ICourseTag[]> => {
    const response = await this.apiClient.get(ENDPOINTS.fetchCourseTags(), { courseId });
    return response;
  }

  public fetchFacultyCourses = async (facultyId: string): Promise<IFacultyCourses[]> => {
    const response = await this.apiClient.get(ENDPOINTS.fetchFacultyCourses(facultyId));
    return response;
  }

  public fetchCourses = async (courseIds: string[], courseRootIds?: string[], nonPaginated?: boolean) => {
    const response = await this.apiClient.get(ENDPOINTS.fetchCourses(), { courseIds, courseRootIds, nonPaginated }, true);
    const courseDetails = deserializeJsonObjCollection(
      response.courses,
      CourseModel,
      "fetchCourses"
    );
    return courseDetails;
  }
  public fetchCourseById = async (courseId: string) => {
    const response = await this.apiClient.get(ENDPOINTS.fetchCourseById(courseId));
    return response;
  }
  public fetchLatestCourses = async (params: IFetchLatestCoursePayload) => {
    const latestCourses = await this.apiClient.get(ENDPOINTS.fetchLatestCourses(), params, true);
    const response = {
      courses: deserializeJsonObjCollection(latestCourses.courses, CourseModel, "fetchLatestCourses"),
      total: latestCourses.total
    }
    return response;
  }

  public fetchEnrolledCourses = async (payload: IFetchEnrolledCourses) => {
    const { userId, courseId, limit, pageNumber, status, courseRootIds } = payload;
    const enrollCoursePayload = { userId, courseId, limit, pageNumber, status, courseRootIds };
    const response = await this.apiClient.get(ENDPOINTS.fetchEnrolledCourses(), enrollCoursePayload, true);
    return { courses: response.rows, count: response.count };
  }
  public fetchCourseActivityPercentage = async (payload: IFetchCourseActivity) => {
    const { userId, courseIds } = payload;
    const response = await this.apiClient.get(ENDPOINTS.courseActivityPercentage(userId), { courseIds }, true);
    return response;
  };

  public fetchCourseToc = async (studentId: string, courseId: string) => {
    const response = await this.apiClient.get(ENDPOINTS.fetchCourseTOC(studentId, courseId), {}, true);
    const courseToc = deserializeJsonObj(response, CourseToc, "fetchCourseToc");
    return courseToc;
  };

  public fetchLmsSession = async () => {
    const response = await this.apiClient.post(ENDPOINTS.fetchLMSSession(), { provider: "moodle" });
    return response;
  };

  public fetchSingleActivityStatus = async (activityId: number, userId: string, courseId: string, isFinalAssessment: boolean, isCertifiedAssessment: boolean) => {
    const response = await this.apiClient.get(ENDPOINTS.fetchActivityStatus(activityId, userId), { courseId, isFinalAssessment, isCertifiedAssessment });
    return response;
  };

  public getCertificateId = async (payload: IGetCertificateIdPayload) => {
    const response = await this.commonApiClient.get(ENDPOINTS.getCertificateId(), payload);
    return response;
  }

  public connectDiscourse = async (payload: IConnectDicoursePayload) => {
    const { sso, sig, userId } = payload;
    const params = {
      sso,
      sig
    };
    const payloadBody = {
      userId
    };
    const response = await this.apiClient.post(ENDPOINTS.connectDiscourse(), payloadBody, params);
    return response;
  }
  updateLastVisitedTime

  public updateCoursesLastVisitedTime = async (userId: string, courseId: string) => {
    const response = await this.apiClient.patch(ENDPOINTS.updateLastVisitedTime(userId, courseId));
    return response;
  }
  public getAiLessonMapping = async (lessonId: string) => {
    const response = await this.apiClient.get(ENDPOINTS.getLessonMapping(), { lessonId });
    return response;
  }
  public fetchCertifiedFacultyCourses = async (payload: IGetCertifiedFacultyCourses) => {
    const response = await this.apiClient.get(ENDPOINTS.fetchCertifiedFacultyCourses(), payload);
    return response;
  }

  public fetchOpenCourseList = async (payload: IFetchOpenCourseListPayload) => {
    const { userId, limit, page, unEnrolledCourses, instituteId, language } = payload;
    const payloadData = { unEnrolledCourses, instituteId: (instituteId == config.DEFAULT_INSTITUTE_ID) ? undefined : instituteId, language, limit }
    const response = await this.apiClient.get(ENDPOINTS.fetchOpenCourseList(userId), payloadData, true);
    const openCourseList = deserializeJsonObjCollection(
      response.courses,
      CourseModel,
      "fetchOpenCourses"
    );
    const responseData = {
      courses: openCourseList,
      totalCount: response?.total
    }
    return responseData;
  };

  public enrollToCourse = async (payload: IEnrollCoursePayload) => {
    const response = await this.apiClient.post(ENDPOINTS.enrollToCourse(), payload);
    return response;
  }

  public getEnrollToCourse = async (payload: IEnrollCoursePayload) => {
    const response = await this.apiClient.get(ENDPOINTS.enrollToCourse(), payload);
    return response;
  }

  public getForumDetailsById = async (payload: IFetchForumDetailsByIdPayload) => {
    const params = {
      tags:  JSON.stringify(payload)
    }
    const response = await this.apiClient.get(ENDPOINTS.fetchForumDetailsByCourseKey(), params);
    return response;
  }

  public discourseSyncToGroup = async (payload: IDiscourseSyncToGroupPayload) => {
    const response = await this.apiClient.post(ENDPOINTS.discourseSyncToGroup(), payload);
    return response
  }
  
  public fetchEnrolledCourseKeys = async (payload) => {
    const { userId, status } = payload
    const response = await this.apiClient.get(ENDPOINTS.fetchEnrolledCourseKeys(userId), { status });
    return response;
  }

  public fetchInstituteCourses = async payload => {
    const { pageNumber, limit, userId, instituteIds, language } = payload;
    const response = await this.apiClient.get(
      ENDPOINTS.fetchInstituteOpenCourses(userId),
      {
        pageNumber,
        limit,
        instituteIds,
        language,
      },
    );
    return {
      courses: deserializeJsonObjCollection(
        response.courses,
        CourseModel,
        'fetchInstituteCourses',
      ) as CourseModel[],
      total: response.totalCount,
    };
  };

  public getCourseDetailFromCourseKey = async (params) => {
    const { userId } = params;
    const getCourseDetail = await this.apiClient.get(ENDPOINTS.getCourseDetailFromCourseKey(userId), params);
    if(getCourseDetail?.courseData) {
      return deserializeJsonObj(getCourseDetail?.courseData, CourseModel, "guestCoursesList");
    } else {
      Navigator.replace(NavigationUrl.generate(URLs.home));
      return null;
    }
  }

  public getMultipleLessonAiMapping = async (payload: IFtechMultipleLessonAiMappingPayload) => {
    const response = await this.apiClient.get(ENDPOINTS.getLessonMapping(), payload);
    return response;
  }

  public getForumTrendingTopic = async (payload: IFetchForumTrendingTopicPayload) => {
    const response = await this.apiClient.get(ENDPOINTS.fetchForumTrendingTopic(), payload);
    return response;
  }
}


const courseRepository = new CourseRepository(ApiClient, wfcommonApiClient);

export { courseRepository as CourseRepository };



import { BatchModel, IInvitedStudent, IInviteSingleResponse, IResendOTPResponse } from "@common/domain/models/Batch";
import { BatchDeliveryModel } from "@common/domain/models/BatchDeliveryModel";
import { RMBatchModel } from "@common/domain/models/RMBatchModel";

import { IState } from "@store/webInterfaces";
import { IJoinBatchMessage, IUserBatchPermissions } from "./interface";

const isBatchStateUndefined = (state: IState) => state.batch === undefined;

const isBatchErrorsUndefined = (state: IState) =>
  state.batch.errors === undefined;

const isBatchLoaderUndefined = (state: IState) =>
  state.batch.loaders === undefined;

export const getBatchMapSelector = (state: IState): any => {
  if (isBatchStateUndefined(state) || !state.batch.byId) return {};

  return state.batch.byId;
};

export const fetchBatchesLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.fetchBatchesUserOwned
  )
    return false;
  return state.batch.loaders.fetchBatchesUserOwned;
};

export const fetchBatchesErrorSelector = (state: IState): string => {
  if (
    isBatchStateUndefined(state) ||
    isBatchErrorsUndefined(state) ||
    !state.batch.errors.fetchBatchesUserOwned
  )
    return "";
  return state.batch.errors.fetchBatchesUserOwned;
};

export const fetchBatchesSelector = (state: any): BatchModel[] => {
  if (isBatchStateUndefined(state) || !state.batch.batches) return [];

  return state.batch.batches.map((batchId) =>
    getBatchByIdSelector(state, batchId)
  );
};

export const getBatchByIdSelector = (
  state: IState,
  batchId: number
): BatchModel => {
  if (isBatchStateUndefined(state) || !state.batch.byId) return null;

  return state.batch.byId[batchId];
};

export const fetchActiveBatchListSelector = (state: IState): BatchModel[] => {
  if (isBatchStateUndefined(state) || !state.batch.activeBatches) return [];
  return state.batch.activeBatches;
};

export const fetchInactiveBatchListSelector = (state: IState): BatchModel[] => {
  if (isBatchStateUndefined(state) || !state.batch.inactiveBatches) return [];
  return state.batch.inactiveBatches;
};

export const fetchActiveBatchCountSelector = (state: IState): number => {
  if (isBatchStateUndefined(state) || !state.batch.activeBatchCount) return 0;
  return state.batch.activeBatchCount;
}

export const fetchInActiveBatchCountSelector = (state: IState): number => {
  if (isBatchStateUndefined(state) || !state.batch.inactiveBatchCount) return 0;
  return state.batch.inactiveBatchCount;
}

export const closeBatchLoaderSelector = (state: any): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.closeBatch
  )
    return false;
  return state.batch.loaders.closeBatch;
};

export const closeBatchErrorSelector = (state: any): string => {
  if (
    isBatchStateUndefined(state) ||
    isBatchErrorsUndefined(state) ||
    !state.batch.errors.closeBatch
  )
    return "";
  return state.batch.errors.closeBatch;
};

export const closeBatchSelector = (state: any) => {
  if (isBatchStateUndefined(state) || !state.batch.batchStatus) return null;

  return state.batch.batchStatus;
};

export const fetchCompetenciesListSelector = (state: any) => {
  const { competenciesList } = state && state.batch;
  if (isBatchStateUndefined(state) || !competenciesList) return null;
  return competenciesList;
};

export const fetchCompetenciesListLoaderSelector = (state: any) => {
  const { fetchBatchCompetencies } = state && state.batch.loaders;
  if (isBatchStateUndefined(state) || !fetchBatchCompetencies) return false;
  return fetchBatchCompetencies;
};

export const fetchCompetenciesListErrorSelector = (state: any) => {
  const { fetchBatchCompetencies } = state && state.batch.errors;
  if (isBatchStateUndefined(state) || !fetchBatchCompetencies) return "";
  return fetchBatchCompetencies;
};

export const updateCompetencyDateStatus = (state: any) => {
  const { setCompetencyDate } = state && state.batch;
  if (isBatchStateUndefined(state) || !setCompetencyDate) return null;
  return setCompetencyDate;
};

export const updateCompetencyDateLoaderSelector = (state: any) => {
  const { competencyDateUpate } = state && state.batch.loaders;
  if (isBatchStateUndefined(state) || !competencyDateUpate) return false;
  return competencyDateUpate;
};

export const updateCompetencyDateErrorSelector = (state: any) => {
  const { competencyDateUpate } = state && state.batch.errors;
  if (isBatchStateUndefined(state) || !competencyDateUpate) return "";
  return competencyDateUpate;
};

export const fetchBatchDeliveryModelsSelector = (
  state: IState
): BatchDeliveryModel[] | null => {
  if (isBatchStateUndefined(state) || !state.batch.batchDeliveryModels)
    return null;
  return state.batch.batchDeliveryModels;
};

export const fetchBatchDeliveryModelLoaderSelector = (
  state: IState
): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.fetchBatchDeliveryModels
  )
    return false;
  return state.batch.loaders.fetchBatchDeliveryModels;
};

export const fetchBatchDeliveryModelErrorSelector = (state: IState): string => {
  if (
    isBatchStateUndefined(state) ||
    isBatchErrorsUndefined(state) ||
    !state.batch.errors.fetchBatchDeliveryModels
  )
    return "";
  return state.batch.errors.fetchBatchDeliveryModels;
};

export const createBatchSelector = (state): BatchModel => {
  if (isBatchStateUndefined(state) || !state.batch.batchCreate) return null;
  return state.batch.batchCreate as BatchModel;
};

export const createBatchLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.createBatch
  )
    return false;
  return state.batch.loaders.createBatch;
};

export const createBatchErrorSelector = (state: IState): string => {
  if (
    isBatchStateUndefined(state) ||
    isBatchErrorsUndefined(state) ||
    !state.batch.errors.createBatch
  )
    return "";
  return state.batch.errors.createBatch;
};

export const fetchBatchDetailsLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.fetchBatchesDetails
  )
    return false;
  return state.batch.loaders.fetchBatchesDetails;
};

export const fetchBatchDetailsErrorSelector = (state: IState): string => {
  if (
    isBatchStateUndefined(state) ||
    isBatchErrorsUndefined(state) ||
    !state.batch.errors.fetchBatchesDetails
  )
    return "";
  return state.batch.errors.fetchBatchesDetails;
};

export const fetchBatchesIdSelector = (state: IState): BatchModel[] => {
  if (isBatchStateUndefined(state) || !state.batch.batches) return null;
  return state.batch.batches;
};


export const joinBatchLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.joinBatch
  )
    return false;
  return state.batch.loaders.joinBatch;
};

export const joinBatchErrorSelector = (state: IState): string => {
  if (
    isBatchStateUndefined(state) ||
    isBatchErrorsUndefined(state) ||
    !state.batch.errors.joinBatch
  )
    return "";
  return state.batch.errors.joinBatch;
};


export const joinBatchDataSelector = (state: IState): IJoinBatchMessage | null => {
  if (
    isBatchStateUndefined(state) ||
    isBatchErrorsUndefined(state) ||
    !state.batch.joinBatchData
  )
    return null;
  return state.batch.joinBatchData;
};


export const fetchUserBatchesLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.fetchUserBatch
  )
    return false;
  return state.batch.loaders.fetchUserBatch;
};

export const fetchUserbatchErrorSelector = (state: IState): string => {
  if (
    isBatchStateUndefined(state) ||
    isBatchErrorsUndefined(state) ||
    !state.batch.errors.fetchUserBatch
  )
    return "";
  return state.batch.errors.fetchUserBatch;
};

export const fetchUserSelectedBatch = (state: IState): BatchModel[] => {
  if (isBatchStateUndefined(state) || !state.batch.userBatches) return null;
  return state.batch.userBatches;
};

export const fetchStudentFacultyBatchLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.fetchStudentFacultyBatch
  )
    return false;
  return state.batch.loaders.fetchStudentFacultyBatch;
};

export const fetchStudentFacultyBatchErrorSelector = (state: IState): string => {
  if (
    isBatchStateUndefined(state) ||
    isBatchErrorsUndefined(state) ||
    !state.batch.errors.fetchStudentFacultyBatch
  )
    return "";
  return state.batch.errors.fetchStudentFacultyBatch;
};

export const fetchStudentFacultyBatch = (state: IState): BatchModel[] => {
  if (isBatchStateUndefined(state) || !state.batch.studentFacultyBatch) return null;
  return state.batch.studentFacultyBatch;
};

export const fetchBatchesMembersCountLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.isFetchingBatchesMembersCount
  )
    return false;
  return state.batch.loaders.isFetchingBatchesMembersCount;
}

export const fetchBatchesMembersByRoleLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.fetchBatchesMembers
  )
    return false;
  return state.batch.loaders.fetchBatchesMembers;
};

export const fetchBatchFacultiesLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.fetchBatchFaculties
  )
    return false;
  return state.batch.loaders.fetchBatchFaculties;
};

export const fetchBatchStudentsLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.fetchBatchStudents
  )
    return false;
  return state.batch.loaders.fetchBatchStudents;
};

export const fetchBatchRMsLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.fetchBatchRMs
  )
    return false;
  return state.batch.loaders.fetchBatchRMs;
};

// All batches
export const getTotalAllBatchCountSelector = (state: IState): number => {
  if (isBatchStateUndefined(state) || !state.batch.allBatchesCount)
    return 0;
  return state.batch.allBatchesCount;
}

export const fetchAllBatchesSelector = (state: IState) => {
  if (isBatchStateUndefined(state) || !state.batch.allBatchesList) return null;

  return state.batch.allBatchesList.map((batchId) =>
    getBatchByIdSelector(state, batchId)
  );
};

export const fetchAllBatchesErrorSelector = (state: IState): string => {
  if (
    isBatchStateUndefined(state) ||
    isBatchErrorsUndefined(state) ||
    !state.batch.errors.allBatches
  )
    return "";
  return state.batch.errors.allBatches;
};

export const fetchAllBatchesLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.allBatches
  )
    return false;
  return state.batch.loaders.allBatches;
};

export const getAssignedRMBatchMapSelector = (state: IState): RMBatchModel | {} => {
  if (isBatchStateUndefined(state) || !state.batch.rmAssignedBatchesById)
    return {} as any;

  return state.batch.rmAssignedBatchesById;
};

export const getAssignedRMBatchByIdSelector = (
  state: IState,
  rmBatchId: number
): RMBatchModel | null => {
  if (isBatchStateUndefined(state) || !state.batch.rmAssignedBatchesById) return null;
  return state.batch.rmAssignedBatchesById[rmBatchId];
};

export const fetchRMAssignedBatchesLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.rmAssignedBatches
  )
    return false;
  return state.batch.loaders.rmAssignedBatches;
};

export const fetchRMAssignedBatchesErrorSelector = (state: IState): string => {
  if (
    isBatchStateUndefined(state) ||
    isBatchErrorsUndefined(state) ||
    !state.batch.errors.rmAssignedBatches
  )
    return "";
  return state.batch.errors.rmAssignedBatches;
};

export const fetchAssignedRMBatchesSelector = (state: IState) => {
  if (isBatchStateUndefined(state) || !state.batch.batches) return null;
  return state.batch.rmAssignedBatches.map((batch: RMBatchModel) =>
    getAssignedRMBatchByIdSelector(state, batch.getId())
  );
};

export const fetchRMBatchesListSelector = (state: IState): number[] => {
  if (isBatchStateUndefined(state) || !state.batch.rmAssignedBatchesList)
    return [];
  return state.batch.rmAssignedBatchesList;
};

export const getTotalRMBatchCountSelector = (state: IState): number => {
  if (isBatchStateUndefined(state) || !state.batch.rmAssignedBatchesCount)
    return 0;
  return state.batch.rmAssignedBatchesCount;
}

export const fetchBatchesDetailsLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.fetchBatchesDetails
  )
    return false;
  return state.batch.loaders.fetchBatchesDetails;
};

export const fetchBatchesDetailsErrorSelector = (state: IState): string => {
  if (
    isBatchStateUndefined(state) ||
    isBatchErrorsUndefined(state) ||
    !state.batch.errors.fetchBatchesDetails
  )
    return "";
  return state.batch.errors.fetchBatchesDetails;
};

// update batch
export const updateBatchLoaderSelector = (state: IState) => {
  if (isBatchStateUndefined(state) || isBatchLoaderUndefined(state) || !state.batch.loaders.updateBatch) {
    return false;
  }
  return state.batch.loaders.updateBatch;
}

export const updateBatchErrorSelector = (state: IState) => {
  if (isBatchStateUndefined(state) || isBatchErrorsUndefined(state) || !state.batch.errors.updateBatch) {
    return "";
  }
  return state.batch.errors.updateBatch;
}

export const addFacultyLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.addFaculty
  )
    return false;
  return state.batch.loaders.addFaculty;
};

export const addFacultyErrorSelector = (state: IState): string => {
  if (
    isBatchStateUndefined(state) ||
    isBatchErrorsUndefined(state) ||
    !state.batch.errors.addFaculty
  )
    return "";
  return state.batch.errors.addFaculty;
};

export const removeFacultyLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.removeFaculty
  )
    return false;
  return state.batch.loaders.removeFaculty;
};

export const removeFacultyErrorSelector = (state: IState): string => {
  if (
    isBatchStateUndefined(state) ||
    isBatchErrorsUndefined(state) ||
    !state.batch.errors.removeFaculty
  )
    return "";
  return state.batch.errors.removeFaculty;
};

// BATCH USER PERMISSION
export const fetchUserPermissionsByBatchIdMapSelector = (state: IState): IUserBatchPermissions | {} => {
  if (isBatchStateUndefined(state) || !state.batch.fetchUserPermissionsByBatchId)
    return {};

  return state.batch.fetchUserPermissionsByBatchId;
};

export const fetchUserBatchPermissionByBatchIdErrorSelector = (state: IState): string => {
  if (
    isBatchStateUndefined(state) ||
    isBatchErrorsUndefined(state) ||
    !state.batch.errors.fetchUserPermissionsByBatchId
  )
    return "";
  return state.batch.errors.fetchUserPermissionsByBatchId;
};

export const fetchUserBatchPermissionByBatchIdLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.fetchUserPermissionsByBatchId
  )
    return false;
  return state.batch.loaders.fetchUserPermissionsByBatchId;
};

export const invitedStudentsSelector = (state: IState): IInvitedStudent[] => {
  if (isBatchStateUndefined(state) || !state.batch.invitedStudentsList)
    return [];

  return state.batch.invitedStudentsList;
};

export const invitedStudentsCountSelector = (state: IState): number => {
  if (isBatchStateUndefined(state) || !state.batch.invitedStudentsCount)
    return 0;

  return state.batch.invitedStudentsCount;
}

export const fetchInvitedStudentsLoaderSelector = (state: IState): boolean => {
  if (isBatchStateUndefined(state) || !state.batch.loaders.invitedStudents)
    return false;
  return state.batch.loaders.invitedStudents;
}
export const fetchInvitedStudentsErrorSelector = (state: IState): string => {
  if (isBatchStateUndefined(state) || !state.batch.errors.invitedStudents)
    return "";
  return state.batch.errors.invitedStudents;
}

// delete batch students
export const deleteBatchStudentLoaderSelector = (state: IState): boolean => {
  if (
    isBatchStateUndefined(state) ||
    isBatchLoaderUndefined(state) ||
    !state.batch.loaders.deleteBatchStudent
  )
    return false;
  return state.batch.loaders.deleteBatchStudent;
}

export const deleteBatchStudentErrorSelector = (state: IState): string => {
  if (
    isBatchStateUndefined(state) ||
    isBatchErrorsUndefined(state) ||
    !state.batch.errors.deleteBatchStudent
  )
    return "";
  return state.batch.errors.deleteBatchStudent;
};

export const bulkUserUploadSelectors = (state: IState) => {
  if (isBatchStateUndefined(state) || !state.batch.bulkUploadUser)
    return null;

  return state.batch.bulkUploadUser;
}

export const bulkUserUploadLoaderSelectors = (state: IState): boolean => {
  if (isBatchStateUndefined(state) || !state.batch.loaders.uploadUsers)
    return false;

  return state.batch.loaders.uploadUsers;
}

export const bulkUserUploadErrorSelectors = (state: IState) => {
  if (isBatchStateUndefined(state) || !state.batch.errors.uploadUsers)
    return '';

  return state.batch.errors.uploadUsers;
}

export const resendOTPSelector = (state: IState) => {
  if (isBatchStateUndefined(state) || !state.batch.resendOTP)
    return {} as IResendOTPResponse;
  return state.batch.resendOTP;
}

export const resendOTPLoaderSelector = (state: IState) => {
  if (isBatchStateUndefined(state) || !state.batch.loaders.resendOTP)
    return false;
  return state.batch.loaders.resendOTP;
}
export const resendOTPErrorSelector = (state: IState) => {
  if (isBatchStateUndefined(state) || !state.batch.errors.resendOTP)
    return "";
  return state.batch.errors.resendOTP;
}

export const singleStudentInviteSelector = (state: IState) => {
  if (isBatchStateUndefined(state) || !state.batch.inviteSingleStudent)
    return {} as IInviteSingleResponse;
  return state.batch.inviteSingleStudent;
};
export const singleStudentInviteLoaderSelector = (state: IState) => {
  if (isBatchStateUndefined(state) || !state.batch.loaders.inviteSingleStudent)
    return false;
  return state.batch.loaders.inviteSingleStudent;
};

export const singleStudentInviteErrorSelector = (state: IState) => {
  if (isBatchStateUndefined(state) || !state.batch.errors.inviteSingleStudent)
    return '';
  return state.batch.errors.inviteSingleStudent;
};

export const getSetAssessmentOpeningDatesLoaderSelector = (state: IState) => {
  return state?.batch?.loaders?.setAssessmentOpeningDates || false;
}
export const getSetAssessmentOpeningDatesErrorSelector = (state: IState) => {
  return state?.batch?.errors?.setAssessmentOpeningDates || "";
}
export const getSetAssessmentOpeningDatesDataSelector = (state: IState) => {
  return state?.batch?.setAssessmentOpeningDates || [];
}

export const getAssessmentOpeningDatesLoaderSelector = (state: IState) => {
  return state?.batch?.loaders?.getAssessmentOpeningDates || false;
}
export const getAssessmentOpeningDatesErrorSelector = (state: IState) => {
  return state?.batch?.errors?.getAssessmentOpeningDates || null;
}
export const getAssessmentOpeningDatesDataSelector = (state: IState) => {
  return state?.batch?.getAssessmentOpeningDates || [];
}

export const getSetCollaborateActivityJobRolesLoaderSelector = (state: IState) => {
  return state?.batch?.loaders?.setCollaborateActivityJobRoles || false;
}
export const getSetCollaborateActivityJobRolesErrorSelector = (state: IState) => {
  return state?.batch?.errors?.setCollaborateActivityJobRoles || "";
}

export const getCollaborateActivityJobRolesLoaderSelector = (state: IState) => {
  return state?.batch?.loaders?.getCollaborateActivityJobRoles || false;
}
export const getCollaborateActivityJobRolesErrorSelector = (state: IState) => {
  return state?.batch?.errors?.getCollaborateActivityJobRoles || null;
}
export const getCollaborateActivityJobRolesDataSelector = (state: IState) => {
  return state?.batch?.getCollaborateActivityJobRoles || {};
}

export const validateJobRoleLoaderSelector = (state: IState) => {
  return state?.batch?.loaders?.validJobRoles || false;
}
export const validateJobRoleErrorSelector = (state: IState) => {
  return state?.batch?.errors?.validJobRoles || null;
}
export const validateJobRoleDataSelector = (state: IState) => {
  return state?.batch?.validJobRoles || {};
}

export const getCollaborateActivityDetailsLoaderSelector = (state: IState) => {
  return state?.batch?.loaders?.collaborateActivityDetails || false;
}
export const getCollaborateActivityDetailsErrorSelector = (state: IState) => {
  return state?.batch?.errors?.collaborateActivityDetails || null;
}
export const getCollaborateActivityDetailsDataSelector = (state: IState) => {
  return state?.batch?.collaborateActivityDetails || {};
}

export const getIframePostMessageDetails = (state: IState) => {
  return state?.batch?.iframePostMessageDetails || {};
}

export const submitFeedbackLoaderSelector = (state: IState) => {
  return state?.batch?.loaders?.submitFeedback || false;
}
export const submitFeedbackErrorSelector = (state: IState) => {
  return state?.batch?.errors?.submitFeedback || null;
}
export const submitFeedbackDataSelector = (state: IState) => {
  return state?.batch?.submitFeedback || {};
}

import { IJob } from "@common/redux/batch/actions";
import { JsonProperty } from "json-object-mapper";

export interface IFeedbackResponse {
  rating: string;
  score: string;
  feedback: string;
}

interface IFeedbackFromAI {
  evaluationResponse: IFeedbackResponse[]
}

export class CollaborateActivityFeedbackModel {
  @JsonProperty({ name: "activityId", required: false })
  private activityId?: string = "";

  @JsonProperty({ name: "activityName", required: false })
  private activityName?: string = "";

  @JsonProperty({ name: "activityType", required: false })
  private activityType?: string = "";

  @JsonProperty({ name: "courseId", required: false })
  private courseId?: string = "";

  @JsonProperty({ name: "instanceId", required: false })
  private instanceId?: string = "";

  @JsonProperty({ name: "userId", required: false })
  private userId?: string = "";

  @JsonProperty({ name: "job", required: false })
  private job?: IJob = undefined;

  @JsonProperty({ name: "level", required: false })
  private level?: string = "";

  @JsonProperty({ name: "userResponse", required: false })
  private userResponse?: any = {};

  @JsonProperty({ name: "feedbackFromAI", required: false })
  private feedbackFromAI?: IFeedbackFromAI = undefined;

  @JsonProperty({ name: "language", required: false })
  private language?: string = "";

  @JsonProperty({ name: "country", required: false })
  private country?: string = "";

  @JsonProperty({ name: "deleted", required: false })
  private deleted?: boolean = false;

  @JsonProperty({ name: "createdAt", required: false })
  private createdAt?: string = "";

  @JsonProperty({ name: "updatedAt", required: false })
  private updatedAt?: string = "";

  @JsonProperty({ name: "id", required: false })
  private id?: string = "";

  @JsonProperty({ name: "status", required: false })
  private status?: string = "";


  public getLevel(): string {
    return this.level;
  }

  public getActivityType(): string {
    return this.activityType;
  }

  public getActivityName(): string {
    return this.activityName;
  }

  public getActivityId(): string {
    return this.activityId;
  }

  public getCourseId(): string {
    return this.courseId;
  }

  public getInstanceId(): string {
    return this.instanceId;
  }

  public getUserId(): string {
    return this.userId;
  }

  public getJob(): IJob {
    return this.job;
  }

  public getUserResponse(): any {
    return this.userResponse;
  }

  public getFeedbackFromAI(): IFeedbackFromAI {
    return this.feedbackFromAI;
  }

  public getLanguage(): string {
    return this.language;
  }

  public getCountry(): string {
    return this.country;
  }

  public getDeleted(): boolean {
    return this.deleted;
  }

  public getCreatedAt(): string {
    return this.createdAt;
  }

  public getUpdatedAt(): string {
    return this.updatedAt;
  }

  public getId(): string {
    return this.id;
  }

  public getStatus(): string {
    return this.status;
  }
  
}
import { JsonProperty } from "json-object-mapper";
import moment from "moment";

export interface ISlotAvailability {
    day: string;
    intervals: {
        startTime: string;
        endTime: string;
    }[];
}

export interface IDateOverrides {
    date: string;
    isUnavailable: boolean;
}

export interface ISlotBooking {
    "groupName": string;
    "isDefault": boolean;
    "availability": ISlotAvailability[];
    "slotDuration": number;
    "bufferTime": number;
    "_id"?: string;
    "dateOverrides": IDateOverrides[];
}

export interface IAvailableSlots {
    slots?: {startTime: string, endTime: string}[];
    slotDuration?: number;
    timeZone?: string;
}

export const utcToLocal = (time: string) => {
    const [hour, minute] = time.split(":");
    const utcTime = moment.utc().startOf('day').add(hour, "hours").add(minute, "minutes")
    return utcTime.local().format("HH:mm");
}

export class SlotBookingModel {
    @JsonProperty({ name: "groups", required: true })
    private groups: ISlotBooking[] = [] as ISlotBooking[];

    public getGroups(): ISlotBooking[] {
        return this.groups?.map((group) => {
            return {
                ...group,
                availability: group.availability.map((availability) => {
                    return {
                        ...availability,
                        intervals: availability?.intervals?.map((interval) => {
                            return {
                                ...interval,
                                startTime: interval?.startTime,
                                endTime: interval?.endTime,
                            };
                        }) ?? [],
                    };
                })
            };
        });
    }
}

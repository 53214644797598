import { IAvailableSlots, ISlotBooking } from '@common/domain/models/SlotBookingModel';
import { SlotBookingActions } from './actions';

export interface ISlotBookingState {
  slotBooking: ISlotBooking[];
  availableSlots: IAvailableSlots;
  loaders: {
    slotBooking: boolean;
    availableSlots: boolean;
  };
  errors: {
    slotBooking: string;
    availableSlots: string;
  };
}

export const initialState = {
    slotBooking: [{
        groupName: '',
        isDefault: false,
        availability: [],
        slotDuration: 15,
        bufferTime: 0,
        dateOverrides: [],
    }],
    availableSlots: {},
    loaders: {
        slotBooking: false,
        availableSlots: false,
    },
    errors: {
        slotBooking: '',
        availableSlots: '',
    },
};

export const slotBookingReducer = (
    state: ISlotBookingState = initialState,
    action: { type: string; payload: any }
) => {
    switch (action.type) {
        case SlotBookingActions.FETCH_SLOT_BOOKING:
            return {
                ...state,
                loaders: { ...state.loaders, slotBooking: true },
                errors: { ...state.errors, slotBooking: '' },
            };
        case SlotBookingActions.FETCH_SLOT_BOOKING_SUCCESS:
            return {
                ...state,
                slotBooking: action.payload,
                loaders: { ...state.loaders, slotBooking: false },
                errors: { ...state.errors, slotBooking: '' },
            };
        case SlotBookingActions.FETCH_SLOT_BOOKING_FAILURE:
            return {
                ...state,
                loaders: { ...state.loaders, slotBooking: false },
                errors: { ...state.errors, slotBooking: action.payload },
            };
        
        case SlotBookingActions.CREATE_SLOT_BOOKING:
            return {
                ...state,
                loaders: { ...state.loaders, slotBooking: true },
                errors: { ...state.errors, slotBooking: '' },
            };
        case SlotBookingActions.CREATE_SLOT_BOOKING_SUCCESS:
            return {
                ...state,
                slotBooking: action.payload,
                loaders: { ...state.loaders, slotBooking: false },
                errors: { ...state.errors, slotBooking: '' },
            };
        case SlotBookingActions.CREATE_SLOT_BOOKING_FAILURE:
            return {
                ...state,
                loaders: { ...state.loaders, slotBooking: false },
                errors: { ...state.errors, slotBooking: action.payload },
            }
        case SlotBookingActions.UPDATE_SLOT_BOOKING:
            return {
                ...state,
                loaders: { ...state.loaders, slotBooking: true },
                errors: { ...state.errors, slotBooking: '' },
            }
        case SlotBookingActions.UPDATE_SLOT_BOOKING_SUCCESS:
            return {
                ...state,
                slotBooking: action.payload,
                loaders: { ...state.loaders, slotBooking: false },
                errors: { ...state.errors, slotBooking: '' },
            };
        case SlotBookingActions.UPDATE_SLOT_BOOKING_FAILURE:
            return {
                ...state,
                loaders: { ...state.loaders, slotBooking: false },
                errors: { ...state.errors, slotBooking: action.payload },
            };
        case SlotBookingActions.FETCH_AVAILABLE_SLOTS:
            return {
                ...state,
                loaders: { ...state.loaders, availableSlots: true },
                errors: { ...state.errors, availableSlots: '' },
            };
        case SlotBookingActions.FETCH_AVAILABLE_SLOTS_SUCCESS:
            return {
                ...state,
                availableSlots: action.payload,
                loaders: { ...state.loaders, availableSlots: false },
                errors: { ...state.errors, availableSlots: '' },
            };
        case SlotBookingActions.FETCH_AVAILABLE_SLOTS_FAILURE:
            return {
                ...state,
                loaders: { ...state.loaders, availableSlots: false },
                errors: { ...state.errors, availableSlots: action.payload },
            };
        default:
            return state;
    }
};

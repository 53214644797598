import { ApiClient } from "@common/network/client";
import { APIv1, APIv2 } from "@common/network/constants";
import { IApiClient } from "@common/network/interfaces";
import {
    deserializeJsonObjCollection,
} from "@utils/ObjectMapperUtil";
import { CourseModel } from "@models/Course";
import { BatchModel } from "@models/Batch";
import { InstituteCourseModel } from "../models/InstituteCourse";
import { IInstituteAdminDashboardOpenCoursePayload } from "@common/redux/instituteAdmin/interface";
import { IFetchTotalStudentsEnrolled, IFetchTotalStudentsEnrolledInCourses } from "@common/redux/instituteAdmin/action";

const ENDPOINTS = {
    fetchOrganisationDetails: () => `${APIv1}/organizationservice/organisations`,    
    fetchOrganisationId: () =>
    `${APIv1}/organizationservice/organisations/admins`,
    fetchInstituteId: () =>
        `${APIv1}/organizationservice/institute/admins`,
    fetchInstituteData: () =>
        `${APIv2}/organizationservice/institutes`,
    fetchMultipleInstituteData: () =>
        `${APIv2}/organizationservice/institutes`,
    fetchOrganisationData: () =>
        `${APIv1}/organizationservice/organisations/institutes`,
    fetchCourseIds: () =>
        `${APIv2}/organizationservice/batches?nonPaginated=true`,
    fetchCoursesData: () =>
        `${APIv1}/courseservice/courses?nonPaginated=true&status=published`,
    fetchOpenCoursesData: (instituteId) =>
        `${APIv1}/organizationservice/analytics/institute/${instituteId}/opencourses`,
    fetchInstituteDashboardData: (instituteId) =>
        `${APIv1}/organizationservice/analytics/reports/institute/${instituteId}/batches`,
    fetchInstituteDashboardOpenCourseData: (instituteId) =>
        `${APIv1}/organizationservice/analytics/reports/institute/${instituteId}/opencourse`,
    downloadInstituteAdminDataReport: (instituteId) =>
        `${APIv1}/organizationservice/analytics/reports/download/institute/${instituteId}/user/batches`,
    fetchFacultyIds: () =>
        `${APIv1}/organizationservice/institutes/faculties?nonPaginated=true`,
    fetchFacultyData: () =>
        `${APIv1}/profileservice/userprofiles?nonPaginated=true`,
    fetchLastRefreshedData: () =>
        `${APIv1}/organizationservice/analytics/reports/lastRefreshed`,
    fetchTotalUsersEnrolled: () =>
        `${APIv1}/organizationservice/institute/students`,
    fetchTotalUsersEnrolledInCourses: (instituteId) =>
        `${APIv1}/organizationservice/analytics/reports/institute/${instituteId}/usercount/opencourse`
};

class InstituteAdminRepository {
    private apiClient: IApiClient;
    constructor(apiClient: IApiClient) {
        this.apiClient = apiClient;
    }

    public fetchOrganisationId = async (payload): Promise<String[]> => {
        const response = await this.apiClient.get(
            ENDPOINTS.fetchOrganisationId(), { userIds: payload,nonPaginated:true }
        );
        return response;
    };

    public fetchInstituteId = async (payload): Promise<String[]> => {
        const response = await this.apiClient.get(
            ENDPOINTS.fetchInstituteId(), { adminIds: payload }
        );
        return response;
    };

    public fetchInstituteData = async (payload): Promise<String[]> => {
        const response = await this.apiClient.get(
            ENDPOINTS.fetchInstituteData(), { instituteId: payload }
        );
        return response;
    };
    public fetchMultipleInstituteData = async (payload): Promise<String[]> => {
        const response = await this.apiClient.get(
            ENDPOINTS.fetchMultipleInstituteData(), { instituteIds: payload,nonPaginated:true }
        );
        return response;
    };

    public fetchOrganisationData = async (payload): Promise<String[]> => {
        const response = await this.apiClient.get(
            ENDPOINTS.fetchOrganisationData(), { organisationIds: payload,nonPaginated:true }
        );
        return response;
    };
    public organisationDetails = async (payload): Promise<String[]> => {
        const response = await this.apiClient.get(
            ENDPOINTS.fetchOrganisationDetails(), { ids: payload }
        );
        return response;
    };

    public fetchInstituteDashboardData = async (payload): Promise<String[]> => {
        const { instituteId, startDateFrom, startDateTill, status, courseIds, facultyId } = payload;
        const response = await this.apiClient.get(
            ENDPOINTS.fetchInstituteDashboardData(instituteId), { courseIds, startDateFrom, startDateTill, status, facultyId }
        );
        return response;
    };

    public fetchInstituteDashboardOpenCourseData = async (payload: IInstituteAdminDashboardOpenCoursePayload): Promise<String[]> => {
        const { instituteId, startMonth, endMonth, reportType, courseId } = payload;
        const response = await this.apiClient.get(
            ENDPOINTS.fetchInstituteDashboardOpenCourseData(instituteId), { courseId, startMonth, endMonth, reportType }
        );
        return response;
    };

    public downloadInstituteAdminDataReport = async (payload): Promise<String[]> => {
        const { instituteId, startDateFrom, startDateTill, status, courseId } = payload;
        const response = await this.apiClient.get(
            ENDPOINTS.downloadInstituteAdminDataReport(instituteId), { courseId, startDateFrom, startDateTill, status }
        );
        return response;
    };

    public fetchCourseIds = async (instituteId: string) => {
        const params = {
            instituteId,
        };
        const response = await this.apiClient.get(
            ENDPOINTS.fetchCourseIds(),
            params,
        );
        const courseDetails = deserializeJsonObjCollection(response.batches, BatchModel, 'fetchCourseIds') as BatchModel[];
        return courseDetails;
    };

    public fetchCoursesData = async (courseIds: string[]) => {
        const response = await this.apiClient.get(ENDPOINTS.fetchCoursesData(), { courseIds });
        const courseDetails = deserializeJsonObjCollection(
            response.courses,
            CourseModel,
            "fetchCoursesData"
        );
        return courseDetails;
    };

    public fetchOpenCoursesData = async (payload: string) => {
        const response = await this.apiClient.get(ENDPOINTS.fetchOpenCoursesData(payload));
        return response?.data;
    };

    public fetchFacultyIds = async (instituteId: string) => {
        const params = {
            instituteId,
        };
        const response = await this.apiClient.get(
            ENDPOINTS.fetchFacultyIds(),
            params,
        );
        return response?.data;
    };

    public fetchFacultyData = async (userIds: string[]) => {
        const response = await this.apiClient.get(ENDPOINTS.fetchFacultyData(), { userIds });
        return response?.profiles;
    };

    public fetchLastRefreshedData = async () => {
        const response = await this.apiClient.get(ENDPOINTS.fetchLastRefreshedData());
        return response;      
    };

    public fetchTotalStudentEnrolled = async (payload: IFetchTotalStudentsEnrolled) => {
        const response = await this.apiClient.get(ENDPOINTS.fetchTotalUsersEnrolled(), payload);
        return response?.total;      
    };

    public fetchTotalStudentEnrolledInCourses = async (payload: IFetchTotalStudentsEnrolledInCourses) => {
        const { instituteId } = payload;
        const response = await this.apiClient.get(ENDPOINTS.fetchTotalUsersEnrolledInCourses(instituteId), payload);
        return response?.total || 0;      
    };
}

const instituteAdminRepository = new InstituteAdminRepository(ApiClient);

export { instituteAdminRepository as InstituteAdminRepository };